import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiHandler } from '../../services/apiHandler';
import { getTechnology } from '../../services/helper';
import ConfirmAlert from '../confirmAlert';
import ErrorAlert from '../ErrorAlert';
import PrimaryButton from '../PrimaryButton';
import Spinner from '../Spinner';
import SuccessAlert from '../SuccessAlert';
import WarningAlert from '../WarningAlert';
import './_floordetails.scss';
import { IFloorDetailsProps } from './IFloorDetailsProps';

export const FloorDetails: React.FC<IFloorDetailsProps> = (props) => {
  let navigate = useNavigate();
  const [floor, setFloor] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: '',
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: '',
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: '',
    alert: false,
  });
  const [deleteFloorConfirmModal, setDeleteFloorConfirmModal] = useState(false);
  const [totalAssets, setTotalAssets]: any = useState();
  const [totalAssetStatus, setTotalAssetStatus]: any = useState({ fieldTech: 0, cloud: 0, sas: 0 });

  useEffect(() => {
    getFloorDetails();
    getFloorGeoJSON();
  }, [props.floorId]);

  const getFloorDetails = () => {
    setLoading(true);
    ApiHandler({ apiName: 'getFloor', body: {}, params: { floor_id: props.floorId } })
      .then((res) => {
        setFloor(res.data);
        setTotalAssets(res.data.total_assets);
        setTotalAssetStatus({
          fieldTech: res.data.total_field_tech,
          cloud: res.data.total_cloud,
          sas: res.data.total_sas,
        });
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const getFloorGeoJSON = () => {
    setLoading(true);
    ApiHandler({ apiName: 'getFloorGeometry', body: {}, params: { floor_id: props.floorId } })
      .then((res) => {
        setLoading(false);
        props.setFloorGeoJSONUrl(res.data.floor_plan_geom);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const deleteFloor = () => {
    setLoading(true);
    ApiHandler({ apiName: 'deleteFloor', body: {}, params: { floor_id: props.floorId } })
      .then((res) => {
        setLoading(false);
        setSuccess({ message: 'Floor Deleted Successfully', alert: true });
        setTimeout(() => {
          props.setFloorId(null);
          props.navigateTo('buildingDetails');
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const getFloorTypeLabel = (floorType: string) => {
    if (floorType === 'Basement') {
      return 'B';
    } else if (floorType === 'Parking') {
      return 'P';
    } else {
      return 'F';
    }
  };

  return (
    <div className="overflow-auto h-100">
      <div className="app-card position-relative app-card-size justify-content-between right">
        <div>
          {/* Header */}
          {/* <div className="row-wise vertical-center">
              <div
                className="icon-background-active"
                onClick={() => {
                  props.navigateTo("buildingDetails");
                }}
              >
                <span className="material-icons-round icon-active">arrow_back</span>
              </div>
              <label className="floor-name ms-3">
                {getFloorTypeLabel(floor?.floor_type)}
                {floor?.floor_number}
              </label>
            </div> */}

          <div className="row-wise vertical-center space-between px-4 py-3">
            <span className="app-card-action-heading fw-bold">{getFloorTypeLabel(floor?.floor_type)}</span>
            <div className="vertical-center">
              {/* Edit and delete options */}
              <div className="btn-group dropstart">
                <span
                  title="Options"
                  className="material-icons-round user-select-none cursor-pointer me-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  more_vert
                </span>
                <ul className="dropdown-menu">
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => {
                        props.setFloorActionType('edit');
                        props.navigateTo('floorActions');
                      }}>
                      Edit
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item color-red"
                      type="button"
                      onClick={() => {
                        setDeleteFloorConfirmModal(true);
                      }}>
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
              <CloseOutlined
                onClick={() => {
                  props.navigateTo('buildingDetails');
                }}
                className="fs-6"
              />
            </div>
          </div>
          <div className="border-bottom" />

          {/* <hr style={{ borderTop: "5px solid #e5e5e5" }}></hr> */}

          <div className="col-wise h-100 px-4 py-3">
            <span className="text-color-secondary mb-2">Floor Type</span>
            <span className="entity-label mb-3">{floor?.floor_type}</span>

            <span className="text-color-secondary mb-2">Description</span>
            <span className="entity-label mb-3">
              {floor?.description ? floor?.description : <em style={{ color: 'gray' }}>No description added</em>}
            </span>

            <span className="text-color-secondary mb-2">Base from Ground</span>
            <span className="entity-label mb-3">{floor?.base_from_ground} ft</span>

            <span className="text-color-secondary mb-2">Ceiling from Ground</span>
            <span className="entity-label mb-3">{floor?.ceiling_from_ground} ft</span>

            <span className="text-color-secondary mb-2">Floor Base Thickness</span>
            <span className="entity-label mb-3">{floor?.floor_base_thickness} ft</span>

            <div className="row-wise">
              <div className="w-50 me-1 result-box col-wise">
                <span className="result-title">Total on Floor</span>
                <span className="result-label">{totalAssets}</span>
                <div className="row-wise align-items-center">
                  <div title="Field Tech" className="field-tech-circle me-1"></div>
                  <span title="Field Tech" className="me-2 result-title">
                    {totalAssetStatus.fieldTech}
                  </span>

                  <div title="Cloud" className="cloud-circle me-1"></div>
                  <span title="Cloud" className="me-2 result-title">
                    {totalAssetStatus.cloud}
                  </span>

                  {getTechnology() !== 'wifi_6e' ? (
                    <div className="row-wise align-items-center">
                      <div title="SAS" className="sas-circle me-1"></div>
                      <span title="SAS" className="me-2 result-title">
                        {totalAssetStatus.sas}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/* <div className="w-50 ms-1 result-box col-wise">
              </div> */}
            </div>
          </div>
        </div>

        <div className="px-4 py-3">
          <PrimaryButton
            clicked={() => {
              props.navigateTo('buildingDetails');
            }}
            label="Back To Building"
          />
        </div>
      </div>

      <Spinner show={loading} />

      {/* Delete Floor confirmation modal */}
      <ConfirmAlert
        message={`Are you sure you want to delete the floor?`}
        primaryText={'Yes'}
        secondaryText={'No'}
        secondaryAction={() => {
          setDeleteFloorConfirmModal(false);
        }}
        visible={deleteFloorConfirmModal}
        title={'Delete Floor'}
        primaryAction={() => {
          setDeleteFloorConfirmModal(false);
          deleteFloor();
        }}
        onClose={() => {
          setDeleteFloorConfirmModal(false);
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />

      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: '', alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: '', alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};
