import React, { useEffect, useState } from "react";
import { IEditBuildingProps } from "./IEditBuildingProps";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import ConfirmAlert from "../confirmAlert";
import ErrorAlert from "../ErrorAlert";
import SuccessAlert from "../SuccessAlert";
import WarningAlert from "../WarningAlert";
import { ApiHandler } from "../../services/apiHandler";
import LabelledInput from "../LabelledInput";
import Dropzone from "react-dropzone";
import PrimaryButton from "../PrimaryButton";
import { CloseOutlined } from "@ant-design/icons";
import LabelledInputNew from "../LabelledInputNew";

export const EditBuilding: React.FC<IEditBuildingProps> = (props) => {
  let navigate = useNavigate();
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [building, setBuilding] = useState<any>();

  useEffect(() => {
    getBuilding();
  }, [props.buildingId]);

  const getBuilding = () => {
    setLoading(true);
    ApiHandler({ apiName: "getBuilding", body: {}, params: { building_id: props.buildingId } })
      .then((res: any) => {
        setLoading(false);
        setBuilding(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const handleInputChange = (e: any) => {
    setBuilding((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const updateBuilding = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", building.name);
    formData.append("description", building.description);
    formData.append("address", building.address);
    formData.append("total_floors", building.total_floors.toString());

    if (typeof building.image === "object") {
      formData.append("image", building.image);
    }

    ApiHandler({
      apiName: "updateBuilding",
      body: formData,
      params: { building_id: props.buildingId },
    })
      .then((res: any) => {
        setLoading(false);
        setSuccess({ message: "Building Updated Successfully", alert: true });
        setTimeout(() => {
          props.navigateTo("buildingDetails");
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  return (
    <div className="overflow-auto h-100">
      <div className="app-card position-relative app-card-size right">
        <div className="row-wise vertical-center px-4 py-3 space-between">
          {/* <div
            className="icon-background-active"
            onClick={() => {
              props.navigateTo("buildingDetails");
            }}
          >
            <span className="material-icons-round icon-active">arrow_back</span>
          </div> */}

          <span className="app-card-action-heading ">UPDATE BUILDING</span>
          <div
            onClick={() => {
              props.navigateTo("buildingDetails");
            }}
          >
            <CloseOutlined className='fs-6 cursor-pointer' />
          </div>
        </div>
        <div className='border-bottom' />

        <form className="overflow-auto col-wise h-100 px-4 py-3 ">
          <LabelledInputNew
            title="Name*"
            name="name"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={building?.name}
            placeholder="Enter"
          />
          <LabelledInputNew
            title="Total Number of Floors*"
            name="total_floors"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            type="number"
            value={building?.total_floors}
          />
          <div className="form-floating w-100 mb-2">

            <div className='text-color'>
              Description
            </div>
            <textarea
              rows={5}
              id="description"
              className="w-100 textbox-box input-text h-auto p-2"
              placeholder={"Description"}
              name="description"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={building?.description}
            ></textarea>
            {/* <label className="input-label" htmlFor="description">
              Description
            </label> */}
          </div>

          {/* Image Upload */}
          <Dropzone
            multiple={false}
            onDrop={(acceptedFiles: any) => {
              setBuilding((prevValues: any) => {
                return { ...prevValues, image: acceptedFiles[0] };
              });
              console.log(acceptedFiles[0]);
            }}
            accept={{ "image/jpg": [".jpg"], "image/jpeg": [".jpeg"], "image/png": [".png"] }}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="img-dropzone-set mb-2">
                <div
                  {...getRootProps({
                    className: `${![undefined, null, ""].includes(building?.image) ? "img-building-uploaded" : "img-building"
                      }`,
                  })}
                >
                  <input {...getInputProps()} />

                  {[undefined, null, ""].includes(building?.image) ? (
                    <div className="row-wise vertical-center ps-2 w-100 h-100">
                      <span className="material-icons-outlined">add_a_photo</span>
                      <span className="ps-2">Upload Building Image</span>
                    </div>
                  ) : (
                    <div className="row-wise vertical-center ps-2 w-100 h-100">
                      <span className="material-icons-outlined">photo_camera</span>
                      <span className="ps-2">{building?.image["name"] ?? "Image Uploaded"}</span>
                    </div>
                  )}
                </div>
                <div></div>
              </section>
            )}
          </Dropzone>

          <div className="form-floating w-100 mb-2">
            <div className='text-color'>
              Address
            </div>
            <textarea
              rows={5}
              id="address"
              className="w-100 textbox-box input-text h-auto p-2"
              placeholder={"Address"}
              name="address"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={building?.address}
            ></textarea>
            {/* <label className="input-label" htmlFor="address">
              Address*
            </label> */}
          </div>

          <div className="row gx-2">
            <div className="col-6">
              <LabelledInputNew title="Latitude*" name="latitude" disabled value={building?.location?.lat} />
            </div>
            <div className="col-6">
              <LabelledInputNew title="Longitude*" name="longitude" disabled value={building?.location?.lng} />
            </div>
          </div>
        </form>

        <div className="px-4 py-3">
          <PrimaryButton
            // className="my-3"
            label="Update Building"
            clicked={() => {
              setConfirmAlert(true);
            }}
          />
        </div>
      </div>
      <Spinner show={loading} />

      <ConfirmAlert
        message={`Do you confirm changes in the building?`}
        primaryText={"Yes"}
        secondaryText={"No"}
        secondaryAction={() => {
          setConfirmAlert(false);
        }}
        visible={confirmAlert}
        title={"Finish Building Creation"}
        primaryAction={() => {
          setConfirmAlert(false);
          updateBuilding();
        }}
        onClose={() => {
          setConfirmAlert(false);
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};
