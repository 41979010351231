import React, { useEffect, useState } from 'react';
import { ApiHandler } from '../services/apiHandler';
import CBSDAssetTableHeader from './CBSDAssetTableHeader';
import CBSDAssetActions from './CBSDAssetActions';
// import CBSDAssetsNav from './CBSDAssetsNav';
import { exists, getTechType } from '../services/helper';
import './Users.css';
import Spinner from './Spinner';
import ErrorAlert from '../components/ErrorAlert';
import AssetProps from '../interfaces/AssetProps';
import NetworkActivityModal from './NetworkActivityModal';
// import PrimaryButton from './PrimaryButton';
// import SecondaryButton from './SecondaryButton';
import ImportDataModal from './ImportDataModal';
// import { RadioGroup, TablePagination } from '@mui/material';
import { CBSDStatuses, DecommissionStatuses } from '../constants/GlobalConstants';
import DecommAssetProps from '../interfaces/DecommProps';
import DecommissionModal from './DecommissionModal';
import DecommissionAssetNav from './DecommissionAssetsNav';
import { useNavigate } from 'react-router-dom';
import DecommissionSuccessAlert from './DecommissionSuccessAlert';
// import { get } from 'http';
// import SmallPrimaryButton from './SmallPrimaryButton';
import { Table, TableProps } from 'antd';
import moment from 'moment';
// import SmallSecondaryButton from './SmallSecondaryButton';

interface CBSDAssetFilters {
  chartFilters: any;
  searchFilters: any;
  assetFilters: any;
  takeToMap: any; // Function to take user to map.
  viewType: string;
  setAssetSummary: any;
  setViewType: any;
  assetSummary: any;
  refreshAssets: any

}

const CBSDAssets: React.FC<CBSDAssetFilters> = (
  { chartFilters, searchFilters, assetFilters, takeToMap, viewType, setAssetSummary, setViewType, assetSummary, refreshAssets }
) => {
  let navigate = useNavigate();
  const [assetCount, setAssetCount] = useState(0);
  const [assets, setAssets] = useState<any>([]);

  // const [statusType, setStatusType] = useState('');
  // const [assetRowsPerPage, setAssetRowsPerPage] = useState(10);
  const [currentAssetPage, setCurrentAssetPage] = useState(0);
  const [error, setError] = useState({
    message: '',
    alert: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedAssetDetails, setSelectedAssetDetails] = useState<AssetProps>();
  const [selectedDecommAssetDetails, setSelectedDecommAssetDetails] = useState<DecommAssetProps>({} as DecommAssetProps);
  const [assetDetailsModal, showAssetDetailsModal] = useState(false);
  const [decommAssetDetailsModal, setshowDecommAssetDetailsModal] = useState(false);
  const [decommissionSuccessVisible, setDecommissionSuccessVisible] = useState(false);
  const [importModal, showImportModal] = useState(false);

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    getAssets();
  }, [viewType, chartFilters, assetFilters]);

  {
    /** The searchString will be cleared once the searched asset is selected */
  }
  useEffect(() => {
    if (exists(searchFilters.trackingId) || searchFilters.abortSearch) {
      changeViewType();
      // setCurrentAssetPage(0)
    }
  }, [searchFilters]);

  const catchApiError = (error: any) => {
    setLoading(false);
    if (error.doLogout === true) {
      setError({ message: 'Session Timed Out', alert: true });
    } else {
      setError({ message: error.data.message, alert: true });
    }
  };

  {
    /** The viewtype to be changed based on the response from search filter */
  }
  const changeViewType = () => {
    let changedViewType = viewType;
    if (searchFilters && exists(searchFilters.trackingId)) {
      if (searchFilters.status.toLowerCase() === CBSDStatuses.CLOUD.value.toLowerCase()) {
        changedViewType = CBSDStatuses.CLOUD.key;
      } else if (searchFilters.status.toLowerCase() === CBSDStatuses.SAS.value.toLowerCase()) {
        changedViewType = CBSDStatuses.SAS.key;
      } else if (searchFilters.status.toLowerCase() === CBSDStatuses.RMA_REQUESTED.value.toLowerCase()) {
        changedViewType = CBSDStatuses.RMA_REQUESTED.key;
      } else if (searchFilters.status.toLowerCase() === CBSDStatuses.FIELD_TECH.value.toLowerCase()) {
        changedViewType = CBSDStatuses.FIELD_TECH.key;
      } else if (searchFilters.status.toLowerCase() === CBSDStatuses.PRELOADED.value.toLowerCase()) {
        changedViewType = CBSDStatuses.PRELOADED.key;
      } else if (searchFilters.status.toLowerCase() === DecommissionStatuses.DECOMMISSIONED.value.toLowerCase()) {
        changedViewType = DecommissionStatuses.DECOMMISSIONED.key;
      } else if (searchFilters.status.toLowerCase() === DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.value.toLowerCase()) {
        changedViewType = DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.key;
      } else if (searchFilters.status.toLowerCase() === DecommissionStatuses.DECOMMISSION_REQUESTED.value.toLowerCase()) {
        changedViewType = DecommissionStatuses.DECOMMISSION_REQUESTED.key;
      }
    }
    setViewType(changedViewType);
      getAssets(0)
  };

  {
    /** This method will be called whenever there is a change
       happen in the asset status type (cloud or sas or rma) */
  }


  {
    /** This method is used to get the filters for fetching assets*/
  }
  const getFilters = () => {
    let filters: any = {};

    {
      /** Search filters. if tracking_id is set, should not apply other filters */
    }
    if (searchFilters && exists(searchFilters.trackingId)) {
      filters['tracking_id'] = searchFilters.trackingId;
      return filters;
    }

    {
      /** Chart filters */
    }
    if (chartFilters && exists(chartFilters.age)) {
      filters['age'] = chartFilters.age;
    }
    if (chartFilters && exists(chartFilters.mountingType)) {
      filters['mounting_type'] = [chartFilters.mountingType];
    }

    {
      /** Other filters */
    }
    if (assetFilters && exists(assetFilters.mountingType)) {
      filters['mounting_type'] = [assetFilters.mountingType];
    }
    if (assetFilters && exists(assetFilters.installer)) {
      filters['installer'] = [assetFilters.installer];
    }
    if (assetFilters && isDateFilterExists(assetFilters)) {
      filters['date_range'] = {
        from_date: assetFilters.date.startDate,
        to_date: assetFilters.date.endDate,
      };
    }

    return filters;
  };

  {
    /** Method to check date filter exists */
  }
  const isDateFilterExists = (assetFilters: any) => {
    return assetFilters.date && exists(assetFilters.date.startDate) && exists(assetFilters.date.endDate);
  };

  /** This method will get single asset details */
  const getSelectedDecommAssetDetails = (trackingId: string) => {
    setLoading(true);
    ApiHandler({ apiName: 'getDecommAsset', body: {}, params: { tracking_id: trackingId } })
      .then((response: any) => {
        setLoading(false);
        setSelectedDecommAssetDetails(response.data);
        setshowDecommAssetDetailsModal(true);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };
  const getSelectedAssetDetails = (trackingId: string) => {
    setLoading(true);
    ApiHandler({ apiName: 'getAsset', body: {}, params: { tracking_id: trackingId } })
      .then((response: any) => {
        setLoading(false);
        setSelectedAssetDetails(response.data);
        showAssetDetailsModal(true);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };
  const submitToDecommission = (TrackingId: string) => {
    setLoading(true);
    let requestBody: any = {
      tracking_id: TrackingId,
    };
    ApiHandler({ apiName: 'submitDecommission', body: requestBody, params: { tracking_id: TrackingId } })
      .then((response: any) => {
        setLoading(false);
        setshowDecommAssetDetailsModal(false);
        setDecommissionSuccessVisible(true);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const assetRowsPerPage = 10
  {
    /** This method is used to call the asset API to 
       get the summary and list of assets */
  }
  const getAssets = (page: any = null) => {
    {
      /** Get Asset Request Body */
    }
    let requestBody: any = {
      filter: getFilters(),
      status: viewType,
      rows_per_page: assetRowsPerPage,
      page: page ?? currentAssetPage,
    };
        console.log(requestBody, "response data", page,currentAssetPage)

    ApiHandler({ apiName: 'listAssets', body: requestBody, params: {} })
      .then((response: any) => {
        // console.log(response.data, "response data", page,currentAssetPage)
        setAssetCount(response.data?.assets?.assets_length);
        const newList = response.data?.assets?.assets_list
        setAssets(newList);
        // if (page !== null)
        //   setCurrentAssetPage(page + 1)
        // else
        //   setCurrentAssetPage(0)
        if(!searchFilters.abortSearch)
        setCurrentAssetPage(1)
        setAssetSummary({
          fieldCount: response.data?.summary?.field_count,
          cloudCount: response.data?.summary?.cloud_count,
          rmaCount: response.data?.summary?.rma_count,
          sasCount: response.data?.summary?.sas_count,
          decommissionCount: response.data?.summary?.decommission_count,
          decommissionedCount: response.data?.summary?.decommissioned_count,
          physicallyDecommissionedCount: response.data?.summary?.phy_decommission_count,
          requestedDecommissionCount: response.data?.summary?.req_decommission_count,
          preloadedCount: response.data?.summary?.preloaded_count,
        });
      })
      .catch((error: any) => {
        setError({ message: error.data.message, alert: true });
      });
  };

  const exportData = () => {
    setLoading(true);
    ApiHandler({ apiName: 'exportAssets', body: {}, params: {} })
      .then((response: any) => {
        setLoading(false);
        window.open(response.data.url, '_blank');
      })
      .catch((error: any) => {
        catchApiError(error);
      });
  };

  interface DataType {
    key: string;
    serial_number: string;
    cbrs_node_name: number;
    site_id: string;
    mounting_type: string;
    cbsd_category: string;
    username: string;
    updation_time: string;
    age: number;
    tracking_id: string,
    status: string
  }


  const columns: TableProps<DataType>['columns'] = [
    {
      title: <div className='table-header'> Site ID 1 / UID</div>,
      dataIndex: 'tracking_id',
      key: 'tracking_id',
      className:'table-body'
    },
    {
      title:<div className='table-header'> Serial Number</div> ,
      dataIndex: 'serial_number',
      key: 'serial_number',
      className:'table-body'
    },
    {
      title: <div className='table-header'> CBRS Node Name</div> ,
      dataIndex: 'cbrs_node_name',
      key: 'cbrs_node_name',
      className:'table-body'
    }, {
      title: <div className='table-header'> Site ID</div>  ,
      dataIndex: 'site_id',
      key: 'site_id',
      className:'table-body'
    },
    {
      title:<div className='table-header'> Mounting Type</div> ,
      dataIndex: 'mounting_type',
      key: 'mounting_type',
      className:'table-body'
    },
    {
      title: <div className='table-header'> {`${getTechType()} Category`}</div>  ,
      dataIndex: 'cbsd_category',
      key: 'cbsd_category',
      className:'table-body'
    },
    {
      title: <div className='table-header'> Installer</div> ,
      dataIndex: 'username',
      key: 'username',
      className:'table-body'
    },
    {
      title: <div className='table-header'> Last Modified (UTC)</div> ,
      dataIndex: 'updation_time',
      key: 'updation_time',
      render: (updated_time, _record) => {
        return (
          <span> {moment.utc(updated_time).format('MMM Do YYYY HH:mm:ss')}</span>
        )
      },
      className:'table-body'
    },
    {
      title: <div className='table-header'> Age (in Days)</div>,
      dataIndex: 'age',
      key: 'age',
      className:'table-body'
    }
  ]

  return (
    <div className="h-100 mt-3">
      {/* <div className="row-wise justify-content-between align-items-center">
        {/** Asset Navigations */}
      {/*  <div className="d-flex flex-row gap-2">
          <SmallPrimaryButton
            clicked={() => {
              showImportModal(true);
            }}
            icon="download"
            label="Import"
          />
          <SmallPrimaryButton
            clicked={() => {
              exportData();
            }}
            label="Export"
            icon="upload"
          />
        </div>
      </div>*/}

      {/** Asset Table Pagination */}
      {!['PHYSICALLY_DECOMMISSIONED', 'DECOMMISSIONED', 'DECOMMISSION_REQUESTED'].includes(viewType) ? (
        <div className="row-wise justify-content-end">
          {/* <TablePagination
            component="div"
            count={assetCount}
            page={currentAssetPage}
            onPageChange={(e: any, newPage: any) => {
              setCurrentAssetPage(newPage);
            }}
            rowsPerPage={assetRowsPerPage}
            onRowsPerPageChange={(event: any) => {
              setAssetRowsPerPage(event.target.value);
              setCurrentAssetPage(0);
            }}
          /> */}
        </div>
      ) : (
        <div className=" mt-3 row-wise justify-content-between">
          <div className="row-wise justify-content-start">
            <DecommissionAssetNav searchedView={viewType} summary={assetSummary} refreshAssets={refreshAssets} />
          </div>
          {/* <div className="row-wise justify-content-end">
            <TablePagination
              component="div"
              count={assetCount}
              page={currentAssetPage}
              onPageChange={(e: any, newPage: any) => {
                setCurrentAssetPage(newPage);
              }}
              rowsPerPage={assetRowsPerPage}
              onRowsPerPageChange={(event: any) => {
                setAssetRowsPerPage(event.target.value);
                setCurrentAssetPage(0);
              }}
            />
          </div> */}
        </div>
      )}

      {/** Asset Table List */}
      {/* <table className="w-100 d-table">
        <thead className="d-table w-100">
          <tr>
            <CBSDAssetTableHeader />
          </tr>
        </thead>
        <tbody className="user-tablebody mt-0">
          {assets.map((asset: any) => {
            return (
              <tr key={asset.tracking_id}>
                <CBSDAssetActions
                  asset={asset}
                  assetClicked={() => {
                    asset.status !== 'Physically Decommissioned'
                      ? getSelectedAssetDetails(asset.tracking_id)
                      : getSelectedDecommAssetDetails(asset.tracking_id);
                  }}
                />
              </tr>
            );
          })}
        </tbody>
      </table> */}
      <>        {console.log(currentAssetPage, "currentAssetPage",assets)}
      </>
      <div className='height-table'>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                record.status !== 'Physically Decommissioned'
                  ? getSelectedAssetDetails(record.tracking_id)
                  : getSelectedDecommAssetDetails(record.tracking_id);
              }
            }
          }}
          pagination={{
            position: ['topRight'], pageSize: 10, onChange(page, _pageSize) {
              setCurrentAssetPage(page)
              getAssets(page - 1)
            }, current: currentAssetPage, total: assetCount, defaultPageSize: 10, className: 'pagination'
          }}
          columns={columns}
          dataSource={[...assets]}
        />
      </div>
      <NetworkActivityModal
        show={assetDetailsModal && !['Physically Decommissioned'].includes(selectedAssetDetails?.asset?.status ?? '')}
        close={() => {
          showAssetDetailsModal(false);
        }}
        assetDetails={selectedAssetDetails}
        takeToMap={takeToMap}
      />

      <ImportDataModal show={importModal} close={() => showImportModal(false)} />

      <DecommissionModal
        show={decommAssetDetailsModal && selectedDecommAssetDetails?.status === 'Physically Decommissioned'}
        close={() => {
          setshowDecommAssetDetailsModal(false);
        }}
        decommAssetDetails={selectedDecommAssetDetails}
        decommAction={(trackingId: string) => {
          submitToDecommission(trackingId);
        }}
      />

      <DecommissionSuccessAlert
        visible={decommissionSuccessVisible}
        title={'DECOMMISSION SUCCESSFULL'}
        sub_title={`${selectedDecommAssetDetails?.tracking_id} device is successfully decommissioned`}
        primaryText={'Close'}
        primaryAction={() => {
          setDecommissionSuccessVisible(false);
          getAssets();
        }}
      />

      <Spinner show={loading} />
      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />
    </div>
  );
};

export default CBSDAssets;
