import React from "react";
import Dropzone from "react-dropzone";
import "./AssetSiteImage.css";
import PoleDefault from "../assets/pole_default.svg";
import LedDefault from "../assets/led_default.svg";
import MiscDefault from "../assets/misc_default.svg";
import LabelDefault from "../assets/label_default.svg";
import PoleSuccess from "../assets/pole_success.svg";
import LedSuccess from "../assets/led_success.svg";
import MiscSuccess from "../assets/misc_success.svg";
import LabelSuccess from "../assets/label_success.svg";

interface AssetSiteImageProps {
  onUpload: any;
  uploaded: boolean;
  className?: any;
  imageType: "pole" | "led" | "label" | "misc";
}

const AssetSiteImage: React.FC<AssetSiteImageProps> = ({ onUpload, uploaded, className, imageType }) => {
  const defaultIcon: any = {
    pole: PoleDefault,
    led: LedDefault,
    label: LabelDefault,
    misc: MiscDefault,
  };

  const successIcon: any = {
    pole: PoleSuccess,
    led: LedSuccess,
    label: LabelSuccess,
    misc: MiscSuccess,
  };

  return (
    <Dropzone
      multiple={false}
      onDrop={(acceptedFiles: any) => {
        onUpload(acceptedFiles);
      }}
      accept={{ "image/jpg": [".jpg"], "image/jpeg": [".jpeg"], "image/png": [".png"] }}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="row-wise vertical-center my-2">
          <div
            {...getRootProps({
              className: `px-4 space-between vertical-center ${className}
              ${
                uploaded ? "img-dropzone-uploaded" : "img-dropzone"
              }`
              ,
            })}
          >
            <span className="image-label">{imageType}</span>

            <input {...getInputProps()} />

            {uploaded ? (
              <img width={25} src={successIcon[imageType]} />
            ) : (
              <img width={25} src={defaultIcon[imageType]} />
            )}
          </div>
          <div></div>
        </section>
      )}
    </Dropzone>
  );
};

export default AssetSiteImage;
