import React from 'react';
import Dropzone from 'react-dropzone';

import './AccordionInput.css';

type inputTypes = 'input' | 'dropdown' | string;
interface AccordionInputProps {
  type: inputTypes;
  title: string;
  disabled?: boolean;
  options?: any[];
  value?: string;
  onChange?: any;
  icon?: string;
  onUpload?: any;
}

interface DropdownProps {
  type: inputTypes;
  title: string;
  value?: string;
  onChange: any;
  disabled: boolean;
  options: any[];
}

const VendorDropdown: React.FC<DropdownProps> = ({ type, title, value, onChange, disabled = false, options = [] }) =>
  type === 'vendor-dropdown' ? (
    <div className='col-wise px-2'>
      <div className='text-color w-100 pt-1'>
        {title}
      </div>
    <div className="form-floating w-100 h-btn p-0">
      <select
        disabled={disabled}
        className="form-control accordion-input h-btn p-0 px-2"
        value={value}
        id="accordionDropdown"
        onChange={(e: any) => {
          onChange(e);
        }}
      >
        <option hidden>{title}</option>
        {options.map((option: any) => {
          return <option key={option.id} value={option.id}>{`${option.vendor_name}-${option.vendor_model}`}</option>;
        })}
      </select>
      {/* <label htmlFor="accordionDropdown" className="accordion-input-label">
        {title}
      </label> */}
    </div>
    </div>
  ) : (
    <Dropdown type={type} disabled={disabled} onChange={onChange} options={options} title={title} value={value} />
  );

const Dropdown: React.FC<DropdownProps> = ({ type, title, value, onChange, disabled = false, options = [] }) =>
  type === 'dropdown' ? (
    <div className='col-wise px-2'>
      <div className='text-color w-100 pt-2'>
        {title}
      </div>
      <div className="form-floating w-100 h-btn p-0">
        <select
          disabled={disabled}
          className="form-control accordion-input h-btn p-0 px-2"
          value={value}
          id="accordionDropdown"
          onChange={(e: any) => {
            onChange(e);
          }}
        >
          <option hidden>{title}</option>
          {options.map((option: any) => {
            return (
              <option key={option} value={option}>
                {option === '' ? 'NONE' : option}
              </option>
            );
          })}
        </select>
        {/* <label htmlFor="accordionDropdown" className="accordion-input-label">
          {title}
        </label> */}
      </div>
    </div>
  ) : (
    <VendorDropdown type={type} disabled={disabled} onChange={onChange} options={options} title={title} value={value} />
  );

const AccordionInput: React.FC<AccordionInputProps> = ({ type, title, disabled = false, options = [], value, onChange, icon, onUpload }) => {
  return (
    <div>
      {type === 'input' ? (
        <div className="col-wise gap-8 px-2">
          <div className='text-color w-100 pt-2'>
            {title}
          </div>
          <div className="form-floating w-100 h-btn">
            <input
              disabled={disabled}
              className="form-control accordion-input h-btn p-0 px-2"
              id="accordionInput"
              placeholder={title}
              value={value}
              onChange={(e: any) => {
                onChange(e);
              }}
            ></input>
            {/* <label htmlFor="accordionInput" className="accordion-input-label">
              {title}
            </label> */}
          </div>
          {icon ? (
            <Dropzone
              multiple={false}
              onDrop={(acceptedFiles: any) => {
                onUpload(acceptedFiles);
              }}
              accept={{ 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'], 'image/png': ['.png'] }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="center">
                  <div {...getRootProps({})}>
                    <input {...getInputProps()} />

                    <span style={icon === 'done' ? { color: '#00d56e' } : {}} className="material-icons-round accordion-icon">
                      {icon}
                    </span>
                  </div>
                  <div></div>
                </section>
              )}
            </Dropzone>
          ) : (
            ''
          )}
        </div>
      ) : (
        <Dropdown type={type} disabled={disabled} onChange={onChange} options={options} title={title} value={value} />
      )}
    </div>
  );
};

export default AccordionInput;
