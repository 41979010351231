import { useNavigate } from 'react-router-dom';
import { DecommissionObj } from '../interfaces/AddDecommission';
import { ApiHandler } from '../services/apiHandler';
import LabelLessDropdown from './LabelLessDropdown';
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import React, { useEffect, useState } from 'react';
interface DecommissionAlertProps {
  visible: boolean;
  vendorType: string;
  title: string;
  tracking_id: String;
  secondaryText: string | null;
  primaryText: string;
  secondaryAction: () => void;
  primaryAction: (requestObj: DecommissionObj) => void;
}

const DecommissionAlert: React.FC<DecommissionAlertProps> = ({
  visible,
  title,
  tracking_id,
  secondaryAction,
  secondaryText,
  primaryAction,
  primaryText,
  vendorType
}) => {
  let navigate = useNavigate();
  const [decommissionReason, setDecommissionReason] = useState<string>('');
  const [decommissionCategory, setDecommissionCategory] = useState<string>('');
  // const decommissionOptions: string[] = ['Something 1', 'Something 2', 'Something 3', 'Something 4', 'Something 5'];
  const [decommissionOptions, setDecommissionOptions] = useState<string[]>([]);
  const decomReason = () => {
    ApiHandler({ apiName: 'getDecommReason', body: {}, params: {} })
      .then((response: any) => {
        setDecommissionOptions(response.data.reasons);
      })
      .catch((error: any) => {
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };
  useEffect(() => {
    decomReason();
  }, []);

  const sendReasons = () => {
    const obj: DecommissionObj = {
      reason: decommissionCategory,
      comment: decommissionReason,
    };
    primaryAction(obj);
  };

  return (
    <Modal show={visible}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content app-card border-0 p-0">
          <div className="modal-header">
            <div className="d-flex flex-column gap-1 ">
              <h5 className="modal-title">{title}</h5>
              <span className="modal-sub-title">
                <b>{`${vendorType === "Nokia" ? "UID: " : "Site ID 1: "}`}</b> {tracking_id}
              </span>
            </div>
          </div>
          <div className="modal-body">
            <LabelLessDropdown 
              className="mb-2"
              options={decommissionOptions}
              onChange={(e: any) => {
                setDecommissionCategory(e.target.value);
              }}
              title="Select Reason for Decommissioning"
              value={decommissionCategory}
              fullWidth
            />
            <div className="form-floating w-100 mb-0">
              <textarea
                rows={5}
                id="reason"
                className="form-control textbox-box input-text h-auto"
                placeholder={'Comments(optional)'}
                name="reason"
                onChange={(e: any) => {
                  setDecommissionReason(e.target.value);
                }}
                value={decommissionReason}
              ></textarea>
              <label className="input-label" htmlFor="description">
                Comments (optional)
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ flex: '1' }}>
              <SecondaryButton label={secondaryText || ''} disable={false} clicked={secondaryAction} />
            </div>
            <div style={{ flex: '1' }}>
              <PrimaryButton
                label={primaryText}
                disable={false}
                clicked={() => {
                  sendReasons();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DecommissionAlert;
function setError(arg0: { message: string; alert: boolean }) {
  throw new Error('Function not implemented.');
}
