import Icon from '@ant-design/icons';

const BuildingSvg = () => (
    <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.13773 9.37662H9.8435V10.4379H6.13773V9.37662Z" fill="black"/>
<path d="M9.8435 11.4175H6.13773V12.4788H9.8435V11.4175Z" fill="black" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.65886 0.799988C2.37157 0.799988 2.13867 1.0377 2.13867 1.33094V5.01302C1.77057 5.01302 1.47217 5.31142 1.47217 5.67952L1.47217 15.2H14.5202L14.5202 3.50635C14.5202 3.11945 14.2541 2.78569 13.883 2.70703L9.09961 1.69323C8.60309 1.588 8.1372 1.97494 8.1372 2.49254L8.1372 6.91786H7.84395L7.84395 5.67952C7.84395 5.31142 7.54555 5.01302 7.17745 5.01302L7.17745 1.33062C7.17745 1.03756 6.94469 0.799988 6.65757 0.799988L2.65886 0.799988ZM6.8042 6.91786L6.8042 6.07429L2.51192 6.07429L2.51192 14.1387H4.13822L4.13822 8.55058C4.13822 7.78091 4.13822 7.39608 4.37248 7.15697C4.60673 6.91786 4.98377 6.91786 5.73783 6.91786H6.8042ZM5.17797 14.1387H10.8033L10.8033 7.97913H5.17797L5.17797 14.1387ZM11.843 14.1387H13.4581L13.4581 3.70219L9.17694 2.80471V6.91786H10.2434C10.9975 6.91786 11.3745 6.91786 11.6088 7.15697C11.843 7.39608 11.843 7.78091 11.843 8.55058L11.843 14.1387ZM3.17842 5.01302V1.86126L6.1377 1.86126V5.01302L3.17842 5.01302Z" fill="black" />
</svg>
)

const BuildingIcon = (props : any) => (
    <Icon component={BuildingSvg} {...props} />
)

interface iconProps {
    className? : string
    style? : any
}

 export const Building = (props : iconProps) => {
    return (
    <BuildingIcon className = {props.className} style = {props.style} />
    )
 }