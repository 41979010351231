import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiHandler } from '../services/apiHandler';
import ErrorAlert from './ErrorAlert';
import './Search.css';
import { assetStatusDot } from '../constants/GlobalConstants';
import VenueIcon from '../assets/venue_location.png';
import { getOrganisationId, getRole, getTechnology } from '../services/helper';
import { SearchOutlined } from '@ant-design/icons';

interface SearchProps {
  setPlace: any;
  closeSearch: any;
}

const Search: React.FC<SearchProps> = ({ setPlace, closeSearch }) => {
  let navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchString, setSearchString] = useState<string>('');
  const [prefferedWay, setPrefferedWay] = useState('cbsd');
  const [predictions, setPredictions] = useState<google.maps.places.QueryAutocompletePrediction[]>([]);
  const [assets, setAssets] = useState<any[]>([]);
  const [venues, setVenues] = useState<any[]>([]);
  const [searchFocus, setSearchFocus] = useState(false)
  let technologyType = getTechnology() || '';

  useEffect(() => {
    if (prefferedWay === 'address') getAddressPredictions();
    else if (prefferedWay === 'cbsd') getAssetPredictions();
    else getVenuePredictions();
  }, [searchString]);

  const searchAssetApi = (queryParams: any) => {
    ApiHandler({ apiName: 'searchAsset', body: {}, params: { searchTerm: searchString }, queryParams: queryParams })
      .then((response: any) => {
        setAssets(response.data.assets);
      })
      .catch((error: any) => {
        if (error.doLogout === true) {
          setErrorMessage('Session Timed Out');
          setErrorAlert(true);
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        }
      });
  };
  const getAssetPredictions = () => {
    if (searchString === '') {
      setAssets([]);
      return;
    }
    if (getRole() === 'installer' && getOrganisationId() === 1) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          searchAssetApi(pos);
        },
        (e: any) => {
          console.log('Error fetching user location: ', e);
        },
        { timeout: 3000 }
      );
    } else {
      searchAssetApi({});
    }
  };

  const getVenuePredictions = () => {
    if (searchString === '') {
      setVenues([]);
      return;
    }
    ApiHandler({ apiName: 'searchVenue', body: {}, params: { search_string: searchString } })
      .then((response: any) => {
        setVenues(response.data);
      })
      .catch((error: any) => {
        if (error.doLogout === true) {
          setErrorMessage('Session Timed Out');
          setErrorAlert(true);
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        }
      });
  };

  const getAddressPredictions = () => {
    if (searchString === '') {
      setPredictions([]);
    } else {
      const regex = /-?\d+\.\d+/g;
      const latlng: any = searchString.match(regex);
      if (latlng && latlng.length === 2) {
        setPlace({
          lat: parseFloat(latlng[0].toString()),
          lng: parseFloat(latlng[1].toString()),
        });
        return;
      }
      const service = new google.maps.places.AutocompleteService();
      service.getQueryPredictions({ input: searchString }, setAddressPredictions);
    }
  };

  const setAddressPredictions = (
    addressPredictions: google.maps.places.QueryAutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus
  ) => {
    if (status != google.maps.places.PlacesServiceStatus.OK || !addressPredictions) {
      return;
    }
    setPredictions(addressPredictions);
  };

  const geocodePlace = (clickedAddress: string) => {
    setSearchString(clickedAddress);
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: clickedAddress }).then((response: any) => {
      console.log(response.results);
      setPlace({ lat: response.results[0].geometry.location.lat(), lng: response.results[0].geometry.location.lng() });
    });
  };

  return (
    <div className="search-bar right">
      <div className="search-box col-12 space-between ps-3 pe-3">
        <span className='border w-100 border-left-only space-between vertical-center'>
          {getOrganisationId() !== 1 ? (
            <input
              onChange={(e: any) => {
                setSearchString(e.target.value);
              }}
              value={searchString}
              // autoFocus
              onFocus={() => setSearchFocus(true)}
              className="w-100 search-input"
              placeholder="Site ID 1 | Address | Venue"
            ></input>
          ) : (
            <input
              onChange={(e: any) => {
                setSearchString(e.target.value);
              }}
              value={searchString}
              // autoFocus
              onFocus={() => setSearchFocus(true)}
              className="w-100 search-input"
              placeholder="UID | Site ID 1 | Address"
            ></input>
          )}

          {searchFocus && <span className="material-icons-round" onClick={() => { closeSearch(); setSearchFocus(false); setSearchString('') }} style={{ color: '#8f9fac' }}>
            close
          </span>}
        </span>
        <span className='border border-right'>
          <SearchOutlined className='text-color' />
        </span>
      </div>
      {searchFocus &&
        <div className='ms-3 me-3 search-list-new'>
          <ul className="flex-begin search nav nav-tabs w-100 py-2 border border-no-top" role="tablist">
            <li className="nav-item">
              <a
                role="tab"
                href="cbsd"
                data-bs-toggle="tab"
                className="nav-link active text-color p-1"
                onClick={() => {
                  setPrefferedWay('cbsd');
                  setAssets([]);
                  setVenues([]);
                  setPredictions([]);
                  setSearchString('');
                }}
              >
                {technologyType == 'cbrs_5g' ? "CBSD" : "Wi-Fi" }
              </a>
            </li>
            <li className="nav-item">
              <a
                role="tab"
                href="#address"
                data-bs-toggle="tab"
                className="nav-link text-color"
                onClick={() => {
                  setPrefferedWay('address');
                  setAssets([]);
                  setVenues([]);
                  setPredictions([]);
                  setSearchString('');
                }}
              >
                Address
              </a>
            </li>
            {getOrganisationId() !== 1 ? (
              <li className="nav-item">
                <a
                  role="tab"
                  href="#venue"
                  data-bs-toggle="tab"
                  className="nav-link text-color"
                  onClick={() => {
                    setPrefferedWay('venue');
                    setAssets([]);
                    setPredictions([]);
                    setVenues([]);
                    setSearchString('');
                  }}
                >
                  Venue
                </a>
              </li>
            ) : (
              <></>
            )}
          </ul>

          <div className="tab-content overflow-auto mx-3">
            <div className={prefferedWay === 'address' ? 'tab-pane fade show active' : 'tab-pane fade'} id="address" role="tabpanel">
              {predictions.map((address: google.maps.places.QueryAutocompletePrediction) => {
                return (
                  <div
                    key={address.place_id}
                    className="col-12 center pt-2 pb-2 mb-1 address-list-row"
                    onClick={() => {
                      geocodePlace(address.description);
                      setSearchFocus(false)
                      setSearchString('')
                    }}
                  >
                    <span className="material-icons-round col-1" style={{ color: '#e3e3e3' }}>
                      place
                    </span>
                    <span className="col-11 search-result">{address.description}</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="tab-content overflow-auto mx-3">
            <div className={`${prefferedWay === 'cbsd' ? 'tab-pane fade show active' : 'tab-pane fade'}`} id="cbsd" role="tabpanel">
              {assets.map((asset: any) => {
                return (
                  <div
                    key={asset.tracking_id}
                    className="col-12 center pt-2 pb-2 mb-1 address-list-row"
                    onClick={() => {
                      setPlace({ lat: asset.latitude, lng: asset.longitude });
                      setSearchString(asset.tracking_id);
                      setSearchFocus(false)
                      setSearchString('')
                    }}
                  >
                    <div className="col-1">
                      <img src={assetStatusDot[asset.status] ? assetStatusDot[asset.status] : ''} className="asset-image"></img>
                    </div>
                    <div className="col-11">
                      <span className="d-block search-result">{asset.tracking_id}</span>
                      <span className="d-block search-subresult mt-1">{asset.cbrs_node_name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="tab-content overflow-auto mx-3">
            <div className={`${prefferedWay === 'venue' ? 'tab-pane fade show active' : 'tab-pane fade'}`} id="venue" role="tabpanel">
              {venues.map((venue: any) => {
                return (
                  <div
                    key={venue.id}
                    className="col-12 center pt-2 pb-2 mb-1 address-list-row"
                    onClick={() => {
                      setPlace(venue.location);
                      setSearchString(venue.name);
                      closeSearch();
                      setSearchFocus(false)
                      setSearchString('')
                    }}
                  >
                    <div className="col-1">
                      <img src={VenueIcon} className="venue-icon"></img>
                    </div>
                    <div className="col-11">
                      <span className="d-block search-result">{venue.name}</span>
                      <span className="d-block search-subresult mt-1">{venue.description}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      }
      <ErrorAlert
        show={errorAlert}
        onDismiss={() => {
          setErrorAlert(false);
        }}
        message={errorMessage}
      />

    </div>
  );
};

export default Search;
