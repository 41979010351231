//bar chart options
export const barOptions = {
    indexAxis: 'y' as const,
    borderWith:3,
    barPercentage: 0.8,
    categoryPercentage: 1,
    plugins: {
        tooltip: {
            callbacks: {
              label: function (barData:any) {
                return ""
              },
              afterLabel: function (context:any) {
                return ""
              }
            }
        },
        value:{},
        legend: {
            labels: {
                usePointStyle: false,
                boxWidth:10,
                boxHeight:10,
            },
            labelBackgroundColor: "gray",
            labelFontColor: "white"
        },
        title: {
            display: false,
        },
        datalabels: {
            display: false,
        }
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
            grid: {
            display:false,
            drawBorder: false
            },
            ticks: {
                display: false,
            }
        },
        y: {
            stacked: true,
            grid: {
            display:false,
            drawBorder: false
            },
            ticks: {
                display: false,
            }
        },
    }
};


//bar chart data
export const barData = {
    labels: ['AERIAL', 'ROE', 'SMB'],
    datasets: [
        {
            label: 'CLOUD',
            data: [],
            backgroundColor: '#FF9445',
            borderWidth: 2,
            borderColor: 'white'
        },
        {
            label: 'SAS',
            data: [],
            backgroundColor: '#00D56E',
            borderWidth: 2,
            borderColor: 'white'
        },
        {
            label: 'RMA',
            data: [],
            backgroundColor: '#BE34FE',
            borderWidth: 2,
            borderColor: 'white'
        },
    ],
};


//doughnut chart data
export const doughnutData = {
    labels: [''],
    datasets: [
        {
            data: [],
            backgroundColor: [
            '#F59243',
            '#E3472B'
            ],
            borderWidth: 1,
        },
    ],
};


//Doughnut chart options
export const doughnutOptions = {
    plugins: {
        labels: {
            precision: 2,
            display: true
        },
        legend: {
            reverse:true,
            display: true,
            labels: {
                boxWidth:10,
                boxHeight:10
            }
        },
        datalabels: {
            display: false
        },
        title: {
            display: false,
            text: "CPI VS INSTALLERS RATIO"
        },      
        doughnutlabel:{
            labels: [
                {
                    display:true,
                    text:'0',
                    color: "#E3472B",
                    font: {
                        size: 10,
                        weight: 'bold',
                    },
                }, {
                    display:true,
                    text:':',
                    color: "#7a7a7a",
                    font: {
                        size: 10,
                        weight: 'bold',
                    },
                }, {
                    display:true,
                    text:'0',
                    color: "#F59243",
                    font: {
                        size: 10,
                        weight: 'bold',
                    },
                }
            ]
        },
    },
    layout: {
        padding: {
            bottom : 5
        }
    },
    cutout: '50%'
}


//line chart options
export const lineOptions = {
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
            text: "CBSD MOUNTING TYPE"
        },
        datalabels: {
            display: true,
            color: "#4091FC",
            align:245,
            offset:1,
            clamp:true
        }           
    },
    layout: {
        padding: {
            top:20
        }
    },
    scales: {
        y: {
            ticks:{
            display: false,
            },
            grid: {
            display:false,
            drawBorder: false
            },
        },
        x: {
            ticks:{
                display: false,
            },
            grid: {
            display:false,
            drawBorder: false
            }
        }
    }
};
    

//line chart data
export const lineData = {
    labels: ['0-5', '6-10', '11-20',
            '>20'],
    datasets: [
        {
            fill: false,
            lineTension: 0,
            pointBackgroundColor:['#4091FC','white','white','white'],
            borderColor: '#4091FC',
            borderWidth: 2,
            data: []
        }
    ]
};