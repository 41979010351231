import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AssetProps, { SectorObj } from '../interfaces/AssetProps';
import { ApiHandler } from '../services/apiHandler';
import ConfirmAlert from './confirmAlert';
import ErrorAlert from './ErrorAlert';
import LabelLessInput from './LabelLessInput';
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import Spinner from './Spinner';
import SuccessAlert from './SuccessAlert';
import WarningAlert from './WarningAlert';
import PolePrimary from '../assets/pole_primary.svg';
import LedPrimary from '../assets/led_primary.svg';
import MiscPrimary from '../assets/misc_primary.svg';
import LabelPrimary from '../assets/label_primary.svg';
import Decommissionicon from '../assets/decomm_icon.png';
import './AssetDetailsPanel.css';
import { exists, getRegion, getTechnology, getOrganisationId, getTechType } from '../services/helper';
import DecommissionAlert from './DecommissionAlert';
import DecommissionRequestAlert from './DecommissionRequestAlert';
import { DecommissionObj } from '../interfaces/AddDecommission';
import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
interface AssetDetailsPanelProps {
  assetDetails: AssetProps | undefined;
  goBack: any;
  setAction: any;
  rmaSerialNumber: any;
}

const AssetDetailsPanel: React.FC<AssetDetailsPanelProps> = ({ rmaSerialNumber, assetDetails, goBack, setAction }) => {
  let navigate = useNavigate();
  const [replaceModal, showReplaceModal] = useState(false);
  const [decommissionVisible, setDecommissionConfirmVisible] = useState(false);
  const [DecommissionrequestVisible, setDecommissionRequestVisible] = useState(false);
  const [newSerialNumber, setNewSerialNumber] = useState('');
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteImages, setSiteImages] = useState<{ type: string; path: string }[]>();
  const [error, setError] = useState({
    message: '',
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: '',
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: '',
    alert: false,
  });
  const [viewImage, setViewImage] = useState({
    image: '',
    fileName: '',
    popup: false,
  });

  useEffect(() => {
    if (assetDetails?.asset?.images) {
      setSiteImages(assetDetails?.asset?.images);
    }
  }, [assetDetails]);

  const siteImageLabel: any = {
    RADIO_LABEL: 'Radio Label',
    OPERATIONAL_RF_SIGNAL_LEVEL: 'Operational RF Signal Level',
    OPERATIONAL_VOLTAGE_LEVEL: 'Operational Voltage Level',
    COAX_CABLE_CONNECTION_LIGHTS: 'Coax Cable Connection and Lights',
    DIRECTIONAL_COUPLER_RADIO_CONNECTION: 'Connection from Directional Coupler to Radio',
    INSTALLATION_STREET_VIEW: 'Installation Street View',
    MISCELLANEOUS_1: 'Miscellaneous 1',
    MISCELLANEOUS_2: 'Miscellaneous 2',
    MISCELLANEOUS_3: 'Miscellaneous 3',
    MISCELLANEOUS_4: 'Miscellaneous 4',
    SITE_DECOMM_SUCCESS: 'Decommission Success',
    DECOMM_RADIO_LABEL: 'Decommission Radio Label',
    DECOMM_MISCELLANEOUS: 'Miscellaneous',
  };

  const getImage = (image: string) => {
    setLoading(true);
    ApiHandler({ apiName: 'download', body: { path: image }, params: {} })
      .then((response: any) => {
        setViewImage({ image: response.data.file, fileName: image.slice(image.lastIndexOf('/') + 1), popup: true });
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };
  const decommissionAsset = (reasonBody: DecommissionObj) => {
    if (reasonBody.reason.trim() === '') {
      setWarning({ message: 'Decommission Reason category cannot be empty', alert: true });
      return;
    }
    setLoading(true);
    const requestBody: any = {
      tracking_id: assetDetails?.asset.tracking_id,
      reason: reasonBody.reason,
      comment: reasonBody.comment,
    };

    ApiHandler({ apiName: 'decomAsset', body: requestBody, params: {} })
      .then((response: any) => {
        setLoading(false);
        setDecommissionConfirmVisible(false);
        setDecommissionRequestVisible(true);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const downloadImage = () => {
    try {
      saveAs(viewImage.image, `${assetDetails?.asset.tracking_id}_${viewImage.fileName}`);
    } catch (error: any) {
      console.info(error);
    }
  };

  const replaceClicked = (e: any) => {
    if (newSerialNumber.trim() === '') {
      setWarning({ message: 'Serial number cannot be empty', alert: true });
      return;
    }
    rmaSerialNumber(newSerialNumber);
    setAction('rma');
    goBack('editAsset');
  };

  const initiatedecom = () => {
    let count: number = 0;
    assetDetails?.sectors.map((sector: SectorObj) => {
      if (sector.status === 'SAS') {
        count++;
      }
    });
    if (assetDetails?.sectors.length === count) {
      setDecommissionConfirmVisible(true);
    } else {
      setWarning({ message: `${getTechType()} can only be decommissioned if all the sectors are in SAS.`, alert: true });
    }
  };

  const deleteAsset = () => {
    setDeleteConfirmVisible(false);
    setLoading(true);
    ApiHandler({ apiName: 'deleteAsset', body: {}, params: { tracking_id: assetDetails?.asset.tracking_id } })
      .then((response: any) => {
        setLoading(false);
        goBack('home');
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const editClicked = () => {
    setAction('edit');
    goBack('editAsset');
  };

  const initiateRma = () => {
    let count: number = 0;
    assetDetails?.sectors.map((sector: SectorObj) => {
      if (sector.status === 'SAS') {
        count++;
      }
    });
    if (assetDetails?.sectors.length === count) {
      showReplaceModal(true);
    } else {
      setWarning({ message: 'CBSD can only be replaced if all the sectors are in SAS.', alert: true });
    }
  };

  const imageTypes: any = {
    led: LedPrimary,
    pole: PolePrimary,
    label: LabelPrimary,
    misc: MiscPrimary,
  };

  const getDeviceTitle: any = {
    cbrs_5g: 'CBSD',
    wifi_6e: 'Wi-Fi',
    cpe_cbsd: 'CPE',
    cpe_wifi: 'CPE',
    '': 'Asset',
  };

  return (
    <div className="app-card position-relative app-card-size overflow-auto">
      {/* <div className="row-wise vertical-center">
        <div
          className="icon-background-active"
          onClick={() => {
            goBack('home');
          }}>
          <span className="material-icons-round icon-active">arrow_back</span>
        </div>
        <span className="app-card-action-heading ms-3">{`${getDeviceTitle[assetDetails?.asset?.technology_type ?? '']} Details`}</span>
      </div> */}
      <div className="row-wise vertical-center space-between px-4 py-3">
        <span className="app-card-action-heading fw-bold"> {`${getDeviceTitle[assetDetails?.asset?.technology_type ?? '']} Details`}</span>  {/* //ADDING CBSD */}
        <div
          // className="icon-background-active"
          onClick={() => {
            goBack('home');
          }}
        >
          <CloseOutlined className='fs-6' />
        </div>
      </div>
      <span className="asset-name px-4">{assetDetails?.asset?.cbrs_node_name ? assetDetails?.asset?.cbrs_node_name : '-'}</span>
      <span className="asset-description mt-2 px-4">{assetDetails?.asset?.description ? assetDetails?.asset?.description : ''}</span>
      <div className='border-bottom' />

      {assetDetails?.asset?.deployment_location === 'indoor' ? (
        <div className='px-4 py-3'>
          <div className="col-wise w-100 indoor-details px-2">
            <span className="indoor-heading mb-2">Venue Details</span>
            {assetDetails?.asset?.venue_name && (
              <span className="indoor-sub-heading mb-1">
                Venue: <span className="indoor-value">{assetDetails?.asset?.venue_name}</span>
              </span>
            )}
            {assetDetails?.asset?.building_name && (
              <span className="indoor-sub-heading mb-1">
                Building: <span className="indoor-value">{assetDetails?.asset?.building_name}</span>
              </span>
            )}
            {assetDetails?.asset?.floor_number && (
              <span className="indoor-sub-heading">
                Floor: <span className="indoor-value">{assetDetails?.asset?.floor_number}</span>
              </span>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="overflow-auto h-100 px-4 py-3">
        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title">
              {getOrganisationId() !== 1 || assetDetails?.vendor?.vendor_type !== 'Nokia' ? 'Site ID 1:' : 'UID:'}
            </span>
            <span className="value-label mt-1">{assetDetails?.asset?.tracking_id ? assetDetails?.asset?.tracking_id : '-'}</span>
          </div>
          <div className="col col-wise">
            <span className="value-title">Site ID:</span>
            <span className="value-label mt-1">{assetDetails?.asset?.site_id ? assetDetails?.asset?.site_id : '-'}</span>
          </div>
        </div>

        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title mt-3">Latitude:</span>
            <span className="value-label mt-1">{assetDetails?.asset?.latitude ? assetDetails?.asset?.latitude : '-'}</span>
          </div>
          <div className="col d-flex flex-row justify-content-between align-items-end">
            <div className="col-wise">
              <span className="value-title mt-3">Longitude:</span>
              <span className="value-label mt-1">{assetDetails?.asset?.longitude ? assetDetails?.asset?.longitude : '-'}</span>
            </div>
            <span
              style={{ color: '#8F9FAC', cursor: 'pointer', userSelect: 'none' }}
              className="material-icons-round"
              onClick={() => {
                navigator.clipboard.writeText(`${assetDetails?.asset?.latitude}, ${assetDetails?.asset?.longitude}`);
                setSuccess({ message: 'Copied to clipboard', alert: true });
              }}>
              content_copy
            </span>
          </div>
        </div>

        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title mt-3">Height:</span>
            <span className="value-label mt-1">{assetDetails?.asset?.height ? assetDetails?.asset?.height : '-'} ft</span>
          </div>
          <div className="col col-wise">
            <span className="value-title mt-3">Serial #:</span>
            <span className="value-label mt-1">{assetDetails?.asset?.serial_number ? assetDetails?.asset?.serial_number : '-'}</span>
          </div>
        </div>

        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title mt-3">Device MAC:</span>
            <span className="value-label mt-1">{assetDetails?.asset?.device_mac ? assetDetails?.asset?.device_mac : '-'}</span>
          </div>
          <div className="col col-wise">
            <span className="value-title mt-3">Device p/N :</span>
            <span className="value-label mt-1">{assetDetails?.asset?.device_pn ? assetDetails?.asset?.device_pn : '-'}</span>
          </div>
        </div>

        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title mt-3">Docsis Serial #:</span>
            <span className="value-label mt-1">{assetDetails?.asset?.cm_serial_number ? assetDetails?.asset?.cm_serial_number : '-'}</span>
          </div>
          <div className="col col-wise">
            <span className="value-title mt-3">Docsis MAC :</span>
            <span className="value-label mt-1">{assetDetails?.asset?.cm_mac ? assetDetails?.asset?.cm_mac : '-'}</span>
          </div>
        </div>

        <div className="row g-0">
          <div className="col col-wise">
            <span className="value-title mt-3">Docsis P/N #:</span>
            <span className="value-label mt-1">{assetDetails?.asset?.cm_pn ? assetDetails?.asset?.cm_pn : '-'}</span>
          </div>
          <div className="col col-wise">
            <span className="value-title mt-3">gNB ID:</span>
            <span className="value-label mt-1">{assetDetails?.asset?.gnodeb_id ? assetDetails?.asset?.gnodeb_id : '-'}</span>
          </div>
        </div>

        {siteImages?.filter((image: any) =>
          [
            'RADIO_LABEL',
            'INSTALLATION_STREET_VIEW',
            'DIRECTIONAL_COUPLER_RADIO_CONNECTION',
            'COAX_CABLE_CONNECTION_LIGHTS',
            'OPERATIONAL_VOLTAGE_LEVEL',
            'OPERATIONAL_RF_SIGNAL_LEVEL',
          ].includes(image?.type)
        )?.length ? (
          <div className="mt-3">
            <span className="image-title">Site Images</span>
            <div className="d-flex flex-row flex-wrap gap-2 mt-2">
              {siteImages
                ?.filter((image: any) =>
                  [
                    'RADIO_LABEL',
                    'INSTALLATION_STREET_VIEW',
                    'DIRECTIONAL_COUPLER_RADIO_CONNECTION',
                    'COAX_CABLE_CONNECTION_LIGHTS',
                    'OPERATIONAL_VOLTAGE_LEVEL',
                    'OPERATIONAL_RF_SIGNAL_LEVEL',
                  ].includes(image?.type)
                )
                ?.map((image: any) => {
                  return (
                    <div
                      key={image.type}
                      className="d-flex flex-column image-box-site gap-1"
                      onClick={() => {
                        getImage(image.path);
                      }}>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ background: '#F5F5F5', borderRadius: '8px', color: '#8F9FAC', height: '80px', cursor: 'pointer' }}>
                        <span className="material-icons-round">image</span>
                      </div>
                      <span className="image-list-label">{siteImageLabel[image?.type]}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          ''
        )}

        {siteImages?.filter((image: any) => ['MISCELLANEOUS_1', 'MISCELLANEOUS_2', 'MISCELLANEOUS_3', 'MISCELLANEOUS_4'].includes(image?.type))
          ?.length ? (
          <div className="my-3">
            <span className="image-title">Other Images</span>
            <div className="d-flex flex-row flex-wrap gap-2 mt-2">
              {siteImages
                ?.filter((image: any) => ['MISCELLANEOUS_1', 'MISCELLANEOUS_2', 'MISCELLANEOUS_3', 'MISCELLANEOUS_4'].includes(image?.type))
                ?.map((image: any) => {
                  return (
                    <div
                      key={image.type}
                      className="d-flex flex-column image-box-site gap-1"
                      onClick={() => {
                        getImage(image.path);
                      }}>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ background: '#F5F5F5', borderRadius: '8px', color: '#8F9FAC', height: '80px', cursor: 'pointer' }}>
                        <span className="material-icons-round">image</span>
                      </div>
                      <span className="image-list-label">{siteImageLabel[image?.type]}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          ''
        )}

        {siteImages?.filter((image: any) => ['DECOMM_RADIO_LABEL', 'DECOMM_MISCELLANEOUS', 'SITE_DECOMM_SUCCESS'].includes(image?.type))?.length ? (
          <div className="mt-3">
            <span className="image-title">Decommission Images</span>
            <div className="d-flex flex-row flex-wrap gap-2 mt-2">
              {siteImages
                ?.filter((image: any) => ['DECOMM_RADIO_LABEL', 'DECOMM_MISCELLANEOUS', 'SITE_DECOMM_SUCCESS'].includes(image?.type))
                ?.map((image: any) => {
                  return (
                    <div
                      key={image.type}
                      className="d-flex flex-column image-box-site gap-1"
                      onClick={() => {
                        getImage(image.path);
                      }}>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ background: '#F5F5F5', borderRadius: '8px', color: '#8F9FAC', height: '80px', cursor: 'pointer' }}>
                        <span className="material-icons-round">image</span>
                      </div>
                      <span className="image-list-label">{siteImageLabel[image?.type]}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      {!['Physically Decommissioned', 'Decommission Requested', 'Decommissioned'].includes(assetDetails?.asset.status ?? '') ? (
        <div className="h-auto mt-2 col-wise justify-content-end px-4 py-3">
          <div className="row g-0 mb-2">
            <div className="col">
              <SecondaryButton
                icon={<img src={Decommissionicon} alt="delete-icon" style={{ paddingBottom: '5px', width: '16px', height: '20px' }} />}
                clicked={initiatedecom}
                label="Decommission"
              />
            </div>
            {!['wifi_6e', 'cpe_cbsd', 'cpe_wifi', undefined, null].includes(assetDetails?.asset?.technology_type) ? (
              <div className="col ms-2">
                <SecondaryButton icon="sync_alt" clicked={initiateRma} label="RMA" />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="row g-0">
            <div className="col">
              <SecondaryButton
                icon={<DeleteOutlined />}
                type="delete"
                clicked={() => {
                  setDeleteConfirmVisible(true);
                }}
                label="Delete"
              />
            </div>
            <div className="col ms-2">
              <SecondaryButton icon={<EditOutlined />} clicked={editClicked} label="Edit" />
            </div>
          </div>
        </div>
      ) : (
        <>
          {['Physically Decommissioned', 'Decommissioned'].includes(assetDetails?.asset.status ?? '') ? (
            <div className="h-auto mt-2 col-wise justify-content-end px-4 py-3">
              <div className="col">
                <SecondaryButton
                  icon="delete"
                  type="delete"
                  clicked={() => {
                    setDeleteConfirmVisible(true);
                  }}
                  label="Delete"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal show={replaceModal}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{`Replace ${getTechType()}`}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  showReplaceModal(false);
                }}></button>
            </div>
            <div className="modal-body overflow-hidden">
              <span className="app-card-action-heading ms-2" style={{ textTransform: 'none' }}>
                Old Serial Number
              </span>
              <LabelLessInput
                className="mt-2 mb-4"
                placeholder="Old Serial Number"
                value={assetDetails?.asset?.serial_number ? assetDetails?.asset?.serial_number : ''}
                disabled
                w100
              />
              <span className="app-card-action-heading ms-2" style={{ textTransform: 'none' }}>
                New Serial Number
              </span>
              <LabelLessInput
                className="mt-2"
                placeholder="New Serial Number"
                value={newSerialNumber}
                newValue={(value: any) => setNewSerialNumber(value)}
                w100
              />
            </div>
            <div className="modal-footer">
              <PrimaryButton
                label={'Replace'}
                clicked={(e: any) => {
                  replaceClicked(e);
                }}
                className="w-100"
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={viewImage.popup}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{'Site Image'}</h5>
              <div className="row-wise download-box ms-2" onClick={downloadImage}>
                <span className="material-icons-round me-1 download-icon">file_download</span>
                <span className="download-label">DOWNLOAD</span>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  setViewImage({ image: '', fileName: '', popup: false });
                }}></button>
            </div>
            <div className="modal-body p-0 overflow-hidden">
              <img
                loading="eager"
                onLoad={() => {
                  setLoading(false);
                }}
                className="w-100"
                src={viewImage.image}
              />
            </div>
          </div>
        </div>
      </Modal>

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: '', alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: '', alert: false });
        }}
        message={warning.message}
      />
      <Spinner show={loading} />
      <ConfirmAlert
        title={`Delete ${getDeviceTitle[assetDetails?.asset?.technology_type ?? '']}`}
        message={`Are you sure you want to delete ${getDeviceTitle[assetDetails?.asset?.technology_type ?? '']}?`}
        note={`${getOrganisationId() !== 1 || assetDetails?.vendor?.vendor_type !== 'Nokia' ? 'Site ID 1:' : 'UID:'} ${assetDetails?.asset.tracking_id
          }`}
        primaryText="Confirm"
        secondaryText="Cancel"
        secondaryAction={() => {
          setDeleteConfirmVisible(false);
        }}
        primaryAction={() => {
          deleteAsset();
        }}
        visible={deleteConfirmVisible}
        onClose = {() => {
          setDeleteConfirmVisible(false);
        }}
      />
      <DecommissionAlert
        visible={decommissionVisible}
        title={`DECOMMISSION`}
        tracking_id={`${assetDetails?.asset.tracking_id}`}
        vendorType={`${assetDetails?.vendor?.vendor_type}`}
        primaryText="Request Decommission"
        secondaryText="Cancel"
        secondaryAction={() => {
          setDecommissionConfirmVisible(false);
        }}
        primaryAction={(obj: DecommissionObj) => {
          decommissionAsset(obj);
        }}
      />
      <DecommissionRequestAlert
        visible={DecommissionrequestVisible}
        title={'DECOMMISSION REQUEST SUCCESSFULL'}
        sub_title={`${assetDetails?.asset.cbrs_node_name} device decommission request is successfull`}
        primaryText="Close"
        primaryAction={() => {
          setDecommissionRequestVisible(false);
          goBack('home');
        }}
      />
    </div>
  );
};

export default AssetDetailsPanel;
