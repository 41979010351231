/* eslint-disable */

import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import 'mapbox-ui-components/index.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FLOOR_TYPES from '../../constants/floorTypes';
import { ApiHandler } from '../../services/apiHandler';
import { FloorDesignerData } from '../../services/designerHelper';
import ConfirmAlert from '../confirmAlert';
import FloorImportComponents from '../DesignerComponents/FloorImportComponents';
import ErrorAlert from '../ErrorAlert';
import LabelledInputNew from '../LabelledInputNew';
import LabelLessDropdown from '../LabelLessDropdown';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Spinner from '../Spinner';
import SuccessAlert from '../SuccessAlert';
import WarningAlert from '../WarningAlert';
import './_flooractions.scss';
import { IFloorActionsProps } from './IFloorActionsProps';

export const FloorActions: React.FC<IFloorActionsProps> = (props) => {
  let navigate = useNavigate();
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: '',
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: '',
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: '',
    alert: false,
  });
  const [floor, setFloor] = useState({
    building_id: '',
    floor_type: '',
    floor_number: '',
    description: '',
    image: undefined,
    floor_plan_image: undefined,
    floor_plan_geojson: undefined,
    base_from_ground: 0.0,
    ceiling_from_ground: 0.0,
    floor_base_thickness: 0.0,
    id: undefined,
    floor_plan_footprint: undefined,
    floor_plan_geom: undefined,
    floor_plan_overlay_box: undefined,
  });
  const [building, setBuilding] = useState<any>();
  const [venue, setVenue] = useState<any>();
  const [isMapOpen, setIsMapOpen] = useState(false);

  useEffect(() => {
    props.setShowControls(!isMapOpen);
  }, [isMapOpen]);

  useEffect(() => {
    getBuilding();
  }, [props.buildingId]);

  useEffect(() => {
    getVenue();
  }, [props.venueId]);

  useEffect(() => {
    if (props.floorActionType === 'edit') {
      getFloor();
    }
  }, [props.floorActionType, props.floorId]);

  const getFloor = () => {
    setLoading(true);
    ApiHandler({
      apiName: 'getFloor',
      body: {},
      params: { floor_id: props.floorId },
    })
      .then((res: any) => {
        setLoading(false);
        setFloor(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const getBuilding = () => {
    setLoading(true);
    ApiHandler({
      apiName: 'getBuilding',
      body: {},
      params: { building_id: props.buildingId },
    })
      .then((res: any) => {
        setLoading(false);
        setBuilding(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const getVenue = () => {
    setLoading(true);
    ApiHandler({
      apiName: 'getVenue',
      body: {},
      params: { venue_id: props.venueId },
    })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setVenue(response.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const handleInputChange = (e: any) => {
    setFloor((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const createFloor = () => {
    setLoading(true);
    let requestBody: any = {
      building_id: props.buildingId,
      floor_type: floor.floor_type,
      floor_number: floor.floor_number,
      description: floor.description,
      base_from_ground: floor.base_from_ground.toString(),
      ceiling_from_ground: floor.ceiling_from_ground.toString(),
      floor_base_thickness: floor.floor_base_thickness.toString(),
      image: floor.image,
      floor_plan_image: floor.floor_plan_image,
      floor_plan_geojson: floor.floor_plan_geojson,
      floor_plan_overlay_box: floor.floor_plan_overlay_box,
      floor_plan_geom: floor.floor_plan_geom,
      floor_plan_footprint: floor.floor_plan_footprint,
    };

    ApiHandler({ apiName: 'createFloor', body: requestBody, params: {} })
      .then((res: any) => {
        setLoading(false);
        setSuccess({
          message: 'Floor Added Successfully',
          alert: true,
        });
        setTimeout(() => {
          props.navigateTo('buildingDetails');
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const updateFloor = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append('building_id', props.buildingId);
    formData.append('floor_type', floor.floor_type);
    formData.append('floor_number', floor.floor_number);
    formData.append('description', floor.description);
    formData.append('base_from_ground', floor.base_from_ground.toString());
    formData.append('ceiling_from_ground', floor.ceiling_from_ground.toString());
    formData.append('floor_base_thickness', floor.floor_base_thickness.toString());
    if (floor.id) formData.append('id', floor.id);
    if (floor.image) formData.append('image', floor.image);
    if (floor.floor_plan_image) formData.append('floor_plan_image', floor.floor_plan_image);
    if (floor.floor_plan_geojson) formData.append('floor_plan_geojson', floor.floor_plan_geojson);
    if (floor.floor_plan_overlay_box) formData.append('floor_plan_overlay_box', floor.floor_plan_overlay_box);
    if (floor.floor_plan_geom) formData.append('floor_plan_geom', floor.floor_plan_geom);
    if (floor.floor_plan_footprint) formData.append('floor_plan_footprint', floor.floor_plan_footprint);

    ApiHandler({
      apiName: 'updateFloor',
      body: formData,
      params: { floor_id: props.floorId },
    })
      .then((res: any) => {
        setLoading(false);
        setSuccess({
          message: 'Floor Updated Successfully',
          alert: true,
        });
        setTimeout(() => {
          props.navigateTo('buildingDetails');
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const onFloorGeometryReceived = (data: FloorDesignerData | null) => {
    setIsMapOpen(false);
    if (!data) return;
    setFloor((prevValues: any) => {
      return {
        ...prevValues,
        floor_plan_geojson: JSON.stringify(data?.floorGeoJson),
        floor_plan_image: data?.floorPlanImage.image,
        floor_plan_overlay_box: data?.floorPlanImage.coords,
        floor_plan_geom: data?.floorGeom,
        floor_plan_footprint: data?.floorFootprint,
      };
    });
  };

  if (isMapOpen) {
    return <FloorImportComponents building={building} venue={venue} floor={floor} onClose={onFloorGeometryReceived} />;
  }

  return (
    <div className="h-100 w-100 overflow-auto">
      <div className="app-card position-relative app-card-size right">
        {/* <div className="row-wise vertical-center">
          <div
            className="icon-background-active"
            onClick={() => {
              props.navigateTo('buildingDetails');
            }}>
            <span className="material-icons-round icon-active">arrow_back</span>
          </div>

          <span className="app-card-action-heading ms-3">{props.floorActionType === 'add' ? 'ADDING' : 'EDITING'} FLOOR</span>
        </div> */}
        <div className="row-wise vertical-center space-between px-4 py-3">
          <span className="app-card-action-heading fw-bold"> {props.floorActionType === 'add' ? 'ADDING' : 'EDITING'} FLOOR</span>
          <div
            onClick={() => {
              props.navigateTo('buildingDetails');
            }}>
            <CloseOutlined className="fs-6" />
            {/* <span className="material-icons-round icon-active">arrow_back</span> */}
          </div>
        </div>
        <div className="border-bottom" />

        <LabelledInputNew title="Building Name" name="building_name" disabled={true} value={building?.name} className="px-4 pt-3 pb-1" />

        <form className="overflow-auto col-wise h-100 px-4 py-1">
          <div className=" my-1">Floor Type</div>
          <LabelLessDropdown
            className="mb-2"
            title="Select"
            options={FLOOR_TYPES}
            fullWidth
            name={'floor_type'}
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={floor.floor_type}
          />

          <LabelledInputNew
            title="Floor Number*"
            name="floor_number"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            type="number"
            value={floor.floor_number}
            placeholder="Enter"
          />

          {/* Description */}
          <div className="form-floating w-100 mb-2">
            <div className=" my-1">Description</div>
            <textarea
              rows={5}
              id="description"
              className="textbox-box input-text h-auto w-100 p-2"
              placeholder={'Description'}
              name="description"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={floor.description}></textarea>
            {/* <label className="input-label" htmlFor="description">
              Description
            </label> */}
          </div>

          <LabelledInputNew
            title="Base from Ground (ft) *"
            className={''}
            type={'number'}
            value={floor?.base_from_ground}
            name="base_from_ground"
            placeholder="Enter Base from Ground height in ft"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
          />

          <LabelledInputNew
            title="Ceiling from Ground (ft) *"
            className={''}
            type={'number'}
            value={floor?.ceiling_from_ground}
            name="ceiling_from_ground"
            placeholder="Enter Ceiling from Ground height in ft"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
          />

          <LabelledInputNew
            title="Floor Base Thickness (ft) *"
            className={''}
            type={'number'}
            value={floor?.floor_base_thickness}
            name="floor_base_thickness"
            placeholder="Enter Floor Base Thickness in ft"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
          />

          {/* Draw Floor Plan */}
          <div className="draw-floor-plan-container mt-2">
            <span className="mb-3">Draw Floor Plan</span>
            <div className="d-flex align-items-start mb-4">
              {/* <span className="material-icons-outlined draw-floor-plan-info">info</span> */}
              <InfoCircleOutlined className="draw-floor-plan-info info-icon-blue" />
              <p className="mx-2">Place the image of your floor plan on the map and make your own digital floor.</p>
            </div>

            {/* Floor Plan Geometry Creation */}
            <SecondaryButton
              className="mb-1 mt-1 w-100"
              label={![undefined, null].includes(floor.floor_plan_geojson) ? 'Edit Floor Plan Geometry' : 'Add Floor Plan Geometry'}
              clicked={() => {
                if ([undefined, null].includes(floor.floor_plan_geojson)) {
                  setIsMapOpen(!isMapOpen);
                }
              }}
              disable={false}
            />
          </div>
        </form>

        <div className="px-4 py-3">
          <PrimaryButton
            className=""
            label={`${props.floorActionType === 'add' ? 'Create' : 'Update'} floor`}
            clicked={() => {
              setConfirmAlert(true);
            }}
            disable={false}
          />
        </div>
      </div>

      <Spinner show={loading} />

      <ConfirmAlert
        message={`Do you confirm ${props.floorActionType === 'add' ? 'creation' : 'updation'} of the floor?`}
        primaryText={'Yes'}
        secondaryText={'No'}
        secondaryAction={() => {
          setConfirmAlert(false);
        }}
        visible={confirmAlert}
        title={` Finish floor ${props.floorActionType === 'add' ? 'Creation' : 'Updation'} `}
        primaryAction={() => {
          setConfirmAlert(false);
          if (props.floorActionType === 'add') {
            createFloor();
          } else {
            updateFloor();
          }
        }}
        onClose={() => {
          setConfirmAlert(false);
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: '', alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: '', alert: false });
        }}
        message={warning.message}
      />

      <Spinner show={false} />

      {isMapOpen ? (
        <div
          id="floor-plan-editor"
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9,
          }}></div>
      ) : null}
    </div>
  );
};
