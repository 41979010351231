import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ImageResizer from 'react-image-file-resizer';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ASSET, DEFAULT_SECTOR } from '../constants/AddAssetDefault';
import AddAssetProps, { AddSectorProps } from '../interfaces/AddAssetProps';
import { ApiHandler } from '../services/apiHandler';
import { exists, getOrganisationId, getRegion, getTechType } from '../services/helper';
import './Asset.css';
import AssetSiteImage from './AssetSiteImage';
import ConfirmAlert from './confirmAlert';
import ErrorAlert from './ErrorAlert';
import LabelledInput from './LabelledInput';
import PrimaryButton from './PrimaryButton';
import Spinner from './Spinner';
import SuccessAlert from './SuccessAlert';
import WarningAlert from './WarningAlert';
import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import LabelledInputNew from './LabelledInputNew';
import SecondaryButton from './SecondaryButton';
import Modal from './Modal';

interface WifiProps {
  goBack: any;
  position: { lat: number; lng: number };
  azimuthActivity: any;
  goToProfile: () => void;
  venuesOnMap: any[];
  setFloorGeoJSONUrl: (url: any) => void;
  unsetFloorGeoJSON: () => void;
  setVenueId: (venueId: any) => void;
  setBuildingId: (buildingId: any) => void;
  setFloorId: (floorId: any) => void;
}

const AddWifi: React.FC<WifiProps> = ({
  goBack,
  position,
  azimuthActivity,
  goToProfile,
  venuesOnMap,
  setFloorGeoJSONUrl,
  unsetFloorGeoJSON,
  setVenueId,
  setBuildingId,
  setFloorId,
}) => {
  const azimuthInterval = useRef<NodeJS.Timer>();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: '',
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: '',
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: '',
    alert: false,
  });
  const [defaultVendorId, setDefaultVendorId] = useState(0);
  const [mountingType, setMountingType] = useState('');
  const [azimuth, setAzimuth] = useState<string[]>([]);
  const [azimuthAngle, setAzimuthAngle] = useState(0);
  const [isAzimuthActive, activateAzimuth] = useState<boolean>(false);
  const [siteImageOne, setSiteImageOne] = useState('');
  const [siteImageTwo, setSiteImageTwo] = useState('');
  const [siteImageThree, setSiteImageThree] = useState('');
  const [siteImageFour, setSiteImageFour] = useState('');
  const [asset, setAsset] = useState<AddAssetProps>(DEFAULT_ASSET);
  const [vendorNotSelected, showVendorNotSelected] = useState(false);
  const [associatedVenue, setAssociatedVenue] = useState<any>(null);
  const [assetOnVenueAlert, setAssetOnVenueAlert] = useState(false);
  const [availableBuildings, setAvailableBuildings] = useState<any[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<any>(null);
  const [availableFloors, setAvailableFloors] = useState<any[]>([]);
  const [selectedFloor, setSelectedFloor] = useState<any>(null);
  const [directionality, setDirectionality] = useState<'Directional' | 'Omni' | ''>('');
  const [imageCount, setImageCount] = useState(0)
  const [showImageModal, setShowImageModal] = useState(false)

  useEffect(() => {
    setMountingType('SMB');
    return () => {
      stopAzimuthRotation();
      cancelAzimuth();
    };
  }, []);

  useEffect(() => {
    if (exists(mountingType)) {
      getDefaultVendorsInfo(mountingType);
    }
  }, [mountingType]);

  // useEffect(() => {
  //   onChangeDirectionality(directionality);
  // }, [directionality]);

  useEffect(() => {
    validateIsAssetOnVenue();
  }, [position]);

  useEffect(() => {
    setAssetOnVenueAlert(associatedVenue !== null);
  }, [associatedVenue]);

  useEffect(() => {
    if (selectedBuilding !== null) {
      listFloors();
    }
  }, [selectedBuilding]);

  // const onChangeDirectionality = (
  //   _directionality: "Directional" | "Omni" | ""
  // ) => {
  //   if (["Omni", ""].includes(_directionality)) return;
  //   if (exists(mountingType) && mountingType === CBSDMountingTypes.AERIAL) {
  //     setAzimuth(["0", "0"]);
  //     return;
  //   }
  //   if (exists(mountingType) && mountingType === CBSDMountingTypes.ROE) {
  //     setAzimuth(["0"]);
  //     return;
  //   }
  // };

  const validateIsAssetOnVenue = () => {
    let contains: boolean = false;
    let tempVenue: any = null;
    for (let venue of venuesOnMap) {
      let doesContain: boolean = window.google.maps.geometry.poly.containsLocation(
        new window.google.maps.LatLng(position.lat, position.lng),
        new window.google.maps.Polygon({ paths: venue?.polygon })
      );
      if (doesContain) {
        contains = doesContain;
        tempVenue = venue;
      }
    }
    if (contains) {
      setAssociatedVenue(tempVenue);
    } else {
      setAssociatedVenue(null);
      setSelectedBuilding(null);
      setSelectedFloor(null);
      showFloorPlan(null);
      setVenueId('');
      setBuildingId('');
      setFloorId('');
      setAvailableBuildings([]);
      setAvailableFloors([]);
    }
  };

  const getDefaultVendorsInfo = (mountingType: string) => {
    setLoading(true);
    const requestParams: any = {
      mountingtype: 'Wifi',
    };
    ApiHandler({
      apiName: 'getDefaultVendorsInfo',
      body: {},
      params: requestParams,
    })
      .then((response: any) => {
        setLoading(false);
        setDefaultVendor(response.data.vendor_id);
        setDirectionality(response.data.directionality);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const setDefaultVendor = (vendorId: any) => {
    if (vendorId === null || vendorId === 0) {
      showVendorNotSelected(true);
      return;
    }
    setDefaultVendorId(vendorId);
  };

  const uploadImageApi = (imageId: any, file: any, imageDataUrl: any) => {
    let requestBody: any = new FormData();
    requestBody.append('file', imageDataUrl);
    requestBody.append('type', `${imageId}`);
    requestBody.append('ext', file[0]['path'].slice(file[0]['path'].lastIndexOf('.') + 1));
    requestBody.append('tracking_id', asset.tracking_id.toString());
    ApiHandler({ apiName: 'upload', params: {}, body: requestBody })
      .then((response: any) => {
        setLoading(false);
        setSuccess({
          message: response.data.message,
          alert: true,
        });
        switch (imageId) {
          case 'pole':
            setSiteImageOne(response.data.path);
            break;
          case 'label':
            setSiteImageTwo(response.data.path);
            break;
          case 'led':
            setSiteImageThree(response.data.path);
            break;
          case 'misc':
            setSiteImageFour(response.data.path);
            break;
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const uploadImage = async (file: any, imageId: string) => {
    if (asset.tracking_id.toString().trim() === '') {
      setWarning({ message: getOrganisationId() !== 1 ? 'Site ID 1 cannot be empty.' : 'UID cannot be empty.', alert: true });
      return;
    }
    setLoading(true);
    ImageResizer.imageFileResizer(
      file[0],
      800,
      800,
      'JPEG',
      100,
      0,
      (uri: any) => {
        uploadImageApi(imageId, file, uri);
      },
      'base64'
    );
  };

  // const getSectorIcons = () => {
  //   let totalIcons: number = 6;
  //   let iconList: any[] = [];
  //   if (mountingType === CBSDMountingTypes.SMB || mountingType === "") {
  //     return iconList;
  //   }
  //   for (let index = 0; index < totalIcons; index++) {
  //     if (
  //       (mountingType === CBSDMountingTypes.AERIAL && index < 2) ||
  //       (mountingType === CBSDMountingTypes.ROE && index < 1)
  //     ) {
  //       const element = <img src={sectorIconSelected}></img>;
  //       iconList.push(element);
  //     } else {
  //       const element = <img src={sectorIcon}></img>;
  //       iconList.push(element);
  //     }
  //   }
  //   return iconList;
  // };

  const validateRequest = () => {
    if (asset.tracking_id.trim() === '') {
      setWarning({ alert: true, message: getOrganisationId() !== 1 ? 'Site ID 1 cannot be empty.' : 'UID cannot be empty.' });
      return false;
    } else if (asset.serial_number.trim() === '') {
      setWarning({ alert: true, message: 'Valid Serial Number is required.' });
      return false;
    } else if (defaultVendorId === 0) {
      showVendorNotSelected(true);
      return false;
    }
    return true;
  };

  const addAssetAPI = (response: any) => {
    let haat = response.data.features[0].properties.haat_average.toFixed(2);
    let sectorList: AddSectorProps[] = [];
    // if (
    //   [CBSDMountingTypes.AERIAL, CBSDMountingTypes.ROE].includes(mountingType)
    // ) {
    //   azimuth.forEach((value: any, index: any) => {
    //     const sector: AddSectorProps = addSectorToRequest(
    //       value,
    //       index + 1,
    //       haat
    //     );
    //     const finalSector: any = { ...sector };
    //     sectorList.push(finalSector);
    //   });
    // }
    // Adding a sector to the SMB to store values in DB but will not be treated as a sector in the functionalities
    // if (mountingType === CBSDMountingTypes.SMB) {
    //   const sector: AddSectorProps = addSectorToRequest("0", 1, haat);
    //   const finalSector: any = { ...sector };
    //   sectorList.push(finalSector);
    // }
    let requestBody: AddAssetProps = asset;
    requestBody.latitude = parseFloat(position.lat.toFixed(6));
    requestBody.longitude = parseFloat(position.lng.toFixed(6));
    requestBody.region = getRegion();
    requestBody.vendor_id = defaultVendorId;
    requestBody.mounting_type = mountingType;
    requestBody.height = parseFloat(requestBody.height.toString());
    requestBody.sectors = sectorList;
    requestBody.venue_id = associatedVenue ? associatedVenue.id : null;
    requestBody.building_id = selectedBuilding ? selectedBuilding.id : null;
    requestBody.floor_id = selectedFloor ? selectedFloor.id : null;
    requestBody.deployment_location = associatedVenue ? 'indoor' : 'outdoor';
    requestBody.technology_type = 'wifi_6e';

    ApiHandler({ apiName: 'addAsset', body: requestBody, params: {} })
      .then((response: any) => {
        setLoading(false);
        goBack('home');
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const addAsset = () => {
    if (!validateRequest()) return;
    setLoading(true);
    calculateElevation()
      .then((response: any) => {
        let rcamsl = response.data.features[0].properties.elevation + parseFloat((parseFloat(asset.height.toString() || '0') / 3.281).toFixed(2));
        calculateHaat(rcamsl)
          .then((response: any) => {
            addAssetAPI(response);
          })
          .catch((error: any) => {
            setLoading(false);
            setError({
              message: error.response.data.features[0].properties.statusMessage,
              alert: true,
            });
          });
      })
      .catch((error: any) => {
        setLoading(false);
        setError({
          message: error.response.data.features[0].properties.statusMessage,
          alert: true,
        });
      });
  };

  const addSectorToRequest = (sectorAzimuth: string, sectorID: number, haat: string): AddSectorProps => {
    let sector: any = DEFAULT_SECTOR;
    sector.sector_id = sectorID.toString();
    sector.tracking_id = asset.tracking_id;
    sector.height = parseFloat((parseFloat(asset.height.toString() || '0') / 3.281).toFixed(2));
    sector.azimuth = parseFloat(sectorAzimuth);
    sector.haat = haat;
    return sector;
  };

  const calculateElevation = () => {
    let requestParams = {
      lat: parseFloat(position.lat.toFixed(6)),
      lon: parseFloat(position.lng.toFixed(6)),
      src: 'ned_1',
      unit: 'm',
    };
    return axios.get('https://geo.fcc.gov/api/contours/elevation.json', {
      params: requestParams,
    });
  };

  const calculateHaat = (rcamsl: number) => {
    let requestParams = {
      lat: parseFloat(position.lat.toFixed(6)),
      lon: parseFloat(position.lng.toFixed(6)),
      nradial: 8,
      rcamsl: rcamsl,
      src: 'ned_1',
      unit: 'm',
    };
    return axios.get('https://geo.fcc.gov/api/contours/haat.json', {
      params: requestParams,
    });
  };

  const handleInputChange = (e: any) => {
    setAsset((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const stopAzimuthRotation = () => {
    clearInterval(azimuthInterval.current);
  };

  const cancelAzimuth = () => {
    azimuthActivity(false);
    activateAzimuth(false);
  };

  const initiateAssociationWithVenue = () => {
    setVenueId(associatedVenue?.id);
    listBuildings();
  };

  const listBuildings = () => {
    setLoading(true);
    ApiHandler({
      apiName: 'listBuildings',
      body: {},
      params: { venue_id: associatedVenue?.id },
    })
      .then((res) => {
        setLoading(false);
        setAvailableBuildings(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const listFloors = () => {
    setLoading(true);
    ApiHandler({
      apiName: 'listFloors',
      body: {},
      params: { building_id: selectedBuilding?.id },
    })
      .then((res) => {
        setLoading(false);
        setAvailableFloors(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const showFloorPlan = (floorId: any) => {
    unsetFloorGeoJSON();
    if (floorId === null) return;
    setLoading(true);
    ApiHandler({
      apiName: 'getFloorGeoJson',
      body: {},
      params: { floor_id: floorId },
    })
      .then((res) => {
        setLoading(false);
        setFloorGeoJSONUrl(res.data.url);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };


  const initiateImageUpload = () => {
    if (asset.tracking_id.trim() === '') {
      setWarning({
        alert: true,
        message: getOrganisationId() !== 1 ? 'Site ID 1 cannot be empty.' : 'UID cannot be empty.',
      });
      return;
    }
    setShowImageModal(true);
  };

  return (
    <div className="h-100 overflow-auto">
      <div className="app-card position-relative app-card-size right">
        {/* <div className="row-wise vertical-center">
          <div
            className="icon-background-active"
            onClick={() => {
              goBack('home');
            }}
          >
            <span className="material-icons-round icon-active">arrow_back</span>
          </div>

          <span className="app-card-action-heading ms-3">ADDING WIFI</span>
        </div> */}

        <div className="row-wise vertical-center space-between px-4 py-3">
          <span className="app-card-action-heading fw-bold">Add New Wi-Fi</span>  {/* //ADDING CBSD */}
          <div
            // className="icon-background-active"
            onClick={() => {
              goBack('home');
            }}
          >
            <CloseOutlined className='fs-6' />
            {/* <span className="material-icons-round icon-active">arrow_back</span> */}
          </div>
        </div>
        <div className='border-bottom' />

        <form className="overflow-auto">
          <div className=' px-4'>
            {associatedVenue !== null &&
              <div className='app-card-action-heading pt-2 pe-1'>
                Venue details
              </div>
            }
            <div className='border-blue mt-2 px-1'>
              <Space>
                <InfoCircleFilled className='info-icon-blue' />
                <span className="asset-drop-info mt-2 mb-2 w-100">{`Click where you'd like to drop the ${getTechType()}`}</span>
              </Space>
            </div>
            <div className=" col-wise py-3 ">
              {associatedVenue === null ? (
                <label className="mb-2 ms-2 association-info">Drop the location pin inside a venue to initiate association.</label>
              ) : (
                <>
                  <div className="text-color">
                    Venue
                  </div>
                  <div className="indoor-value">{associatedVenue.name}</div>
                </>
              )}

              {/* Buildings dropdown */}
              <span className="text-color my-2">Building</span>
              <div className="dropdown mb-2">
                <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {selectedBuilding ? selectedBuilding.name : 'Select Building (optional)'}
                </button>

                <ul className="dropdown-menu w-100">
                  {availableBuildings.map((building: any) => {
                    return (
                      <li key={building.id}>
                        <span
                          onClick={() => {
                            setSelectedBuilding(building);
                            setBuildingId(building.id);
                          }}
                          className="dropdown-item"
                        >
                          {building.name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Floor dropdown */}
              <span className="text-color my-2">Floor</span>
              <div className="dropdown">
                <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {selectedFloor ? selectedFloor.floor_number : 'Select Floor (optional)'}
                </button>
                <ul className="dropdown-menu w-100">
                  {availableFloors.map((floor: any) => {
                    return (
                      <li key={floor?.id}>
                        <span
                          onClick={() => {
                            setSelectedFloor(floor);
                            setFloorId(floor?.id);
                            showFloorPlan(floor?.id);
                          }}
                          className="dropdown-item"
                        >
                          {floor?.floor_number}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className='border-bottom' />

          <div className='mt-1 pb-2 px-4 app-card-action-heading'>
              Settings
            </div>
          <div className='px-4'>
            <LabelledInputNew
              title={getOrganisationId() !== 1 ? 'Site ID 1*' : 'UID*'}
              name="tracking_id"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={asset.tracking_id}
              placeholder='Enter'
            />
            <LabelledInputNew
              title="Wi-Fi Name"
              name="cbrs_node_name"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={asset.cbrs_node_name}
              placeholder='Enter'
            />
            <LabelledInputNew
              title="Site ID"
              name="site_id"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={asset.site_id}
              placeholder='Enter'
            />

            <div className=" w-100 mb-2">
              <div className='mt-1 pb-2 text-color'>
                Description
              </div>
              <textarea
                rows={5}
                id="description"
                className="textbox-box input-text h-auto p-2 w-100"
                placeholder={'Enter'}
                name="description"
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                value={asset.description}
              ></textarea>
              {/* <label className="input-label" htmlFor="description">
              Description
            </label> */}
            </div>

            <div className='mt-1 label-header'>
              Site Images
            </div>

            <div className=' pb-2 text-color'>
              Images &nbsp;
              <span className='upload-count'>{`( ${imageCount}/4 uploaded )`}</span>
            </div>
            <div
              className="image-box p-2 col-wise cursor-pointer w-25 mb-3"
              onClick={() => {
                initiateImageUpload();
              }}>
              <span className="input-label">Upload</span>
            </div>
            {/* <div className="image-box p-2 mb-2">
            <label className="input-label">Site Images</label>
            <div className="row-wise mt-2">
             
            </div>
          </div> */}

            <LabelledInputNew title="Vendor Type" disabled value={directionality} />

            {/* <div className="row-wise w-100 justify-content-evenly mb-2">
            {getSectorIcons().map((icon: any) => icon)}
          </div> */}

            <LabelledInputNew
              title="Antenna Height (ft)*"
              type="number"
              name="height"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={asset.height}
              placeholder='Enter'
            />
            <LabelledInputNew
              title="Serial #*"
              name="serial_number"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={asset.serial_number}
              placeholder='Enter'
            />
            <LabelledInputNew
              title="CM Serial #"
              name="cm_serial_number"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={asset.cm_serial_number}
              placeholder='Enter'
            />
            <LabelledInputNew
              title="CM Mac"
              name="cm_mac"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={asset.cm_mac}
              placeholder='Enter'
            />
            <div className="row gx-2">
              <div className="col-6">
                <LabelledInputNew title="Latitude*" disabled value={position.lat.toFixed(6)} />
              </div>
              <div className="col-6">
                <LabelledInputNew title="Longitude*" disabled value={position.lng.toFixed(6)} />
              </div>
            </div>
          </div>
        </form>
        <div className='row-wise vertical-center px-4 py-3 gap-3'>
          <SecondaryButton
            className="w-100"
            label={"Cancel"}
            clicked={() => goBack('home')}
            disable={false}
          />
          <PrimaryButton className="w-100 blue-bg" label="Save" clicked={addAsset} disable={false} />
        </div>
        {/* <PrimaryButton className="mb-2 me-4 ms-1 mt-2" label="Add Wifi" clicked={addAsset} disable={false} /> */}
      </div>

      {/* Azimuth Image - rotates on button click */}
      {/* <div
        className={
          "position-fixed azimuth-icon-position " +
          (isAzimuthActive ? "" : "d-none")
        }
      >
        <img
          src={mountingType === CBSDMountingTypes.AERIAL ? AerialIcon : RoeIcon}
          style={{ transform: "rotate(" + azimuthAngle + "deg)" }}
        ></img>
      </div> */}

      <Modal show={showImageModal}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{'IMAGES'}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  setShowImageModal(false)
                }}></button>
            </div>
            <div className="modal-body overflow-auto">
              <AssetSiteImage
                imageType="pole"
                className={'me-1'}
                onUpload={(file: any) => {
                  uploadImage(file, 'pole');
                }}
                uploaded={siteImageOne !== ''}
              />
              <AssetSiteImage
                imageType="label"
                className={'ms-1 me-1'}
                onUpload={(file: any) => {
                  uploadImage(file, 'label');
                }}
                uploaded={siteImageTwo !== ''}
              />
              <AssetSiteImage
                imageType="led"
                className={'ms-1 me-1'}
                onUpload={(file: any) => {
                  uploadImage(file, 'led');
                }}
                uploaded={siteImageThree !== ''}
              />
              <AssetSiteImage
                imageType="misc"
                className={'ms-1'}
                onUpload={(file: any) => {
                  uploadImage(file, 'misc');
                }}
                uploaded={siteImageFour !== ''}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Spinner show={loading} />

      <ConfirmAlert
        message={`Vendor for Wi-Fi is not selected. Set it in your profile.`}
        primaryText={'Take me there'}
        secondaryText={"I'll do it later"}
        secondaryAction={() => {
          showVendorNotSelected(false);
        }}
        visible={vendorNotSelected}
        title={` Vendor not selected `}
        primaryAction={() => {
          goToProfile();
        }}
        onClose={() => {
          showVendorNotSelected(false);
        }}
      />

      <ConfirmAlert
        message={`You have moved the location pin in the ${associatedVenue?.name} venue.`}
        note="Asset will be associated to this venue."
        primaryText={'OK'}
        secondaryText={null}
        secondaryAction={() => {
          setAssetOnVenueAlert(false);
          setAssociatedVenue(null);
        }}
        visible={assetOnVenueAlert}
        title={`Location landed in venue `}
        primaryAction={() => {
          setAssetOnVenueAlert(false);
          initiateAssociationWithVenue();
        }}
        onClose={() => {
          setAssetOnVenueAlert(false);
          setAssociatedVenue(null);
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: '', alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: '', alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};

export default AddWifi;
