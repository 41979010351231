import React, { ReactElement } from 'react'

interface Props {
  icon: ReactElement
  title: string
  desc: string
  height?: string
  titleColor?: string
  setCurrentPage?: any
  routePage?: string
  className? : string
  titleClassName? : string
}

export default function ListBuildings(props: Props) {
  return (
    <div onClick={() => {typeof props.setCurrentPage == 'function' && props.routePage && props.setCurrentPage(props.routePage)}} className={`row-wise list-item-venue ${props.className}`} style={{ height: props.height ?? '' }}>
      {props.icon && <div>
        {props.icon}
      </div>
      }
      <div className='me-2 ps-1 '>
        <span style={{ color: props.titleColor ?? undefined }} className={`add-item-title ${props.titleClassName}`}>
          {props.title}
        </span>
        <div className='text-color-secondary'>
          {props.desc}
        </div>
      </div>
    </div>
  )
}