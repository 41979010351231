import { theme } from 'antd';
// @ts-ignore
import { EditorImpl, MapConfigProvider, useMapbox, useViewStore } from 'mapbox-ui-components';
import React, { useEffect, useMemo } from 'react';
import light from '../../assets/designer_light.json';
import { convertLocalToDesigner, parseDesignerResult } from '../../services/designerHelper';
import { GoogleMapsTileAPI } from '../../services/googleTileApi';

const common = {
  token: {
    ...light,
  },
};

const configProps = {
  theme: {
    algorithm: [theme.defaultAlgorithm, theme.compactAlgorithm],
    ...common,
    components: {
      Layout: {
        siderBg: 'white',
        headerBg: 'white',
        footerBg: 'white',
      },
    },
  },
};

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    // position: 'fixed',
  },
};

const USE_GOOGLE_TILES = true;

function FloorImportComponents(props: any) {
  const { building, venue, floor, onClose } = props;
  const {
    location: { lat, lng },
  } = building;
  const setEnableVenueSubTree = useViewStore((state: any) => state.setEnableVenueSubTree);
  const setActiveVenueId = useViewStore((state: any) => state.setActiveVenueId);
  const setActiveBuildingId = useViewStore((state: any) => state.setActiveBuildingId);
  const setActiveLevelId = useViewStore((state: any) => state.setActiveLevelId);
  const setActiveSubTool = useViewStore((state: any) => state.setActiveSubTool);
  const [designerCompatibleData, ids] = convertLocalToDesigner({
    venue,
    building,
    floor,
  });
  const { venueId, buildingId, floorId } = ids;

  useEffect(() => {
    setEnableVenueSubTree(true);
    setActiveVenueId(venueId);
    setActiveBuildingId(buildingId);
    setActiveLevelId(floorId);
    setActiveSubTool('editLevel');
  }, [buildingId, floorId, setActiveBuildingId, setActiveLevelId, setActiveSubTool, setActiveVenueId, setEnableVenueSubTree, venueId]);

  const onCloseHandler = (data: any) => {
    if (Object.values(data).length === 0) return onClose(null);
    const parsedData = parseDesignerResult(data);
    onClose(parsedData);
  };
  return (
    // @ts-ignore
    <div style={styles.container}>
      <MapConfigProvider
        env={{
          REACT_APP_MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
          mapboxToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        }}
        {...configProps}>
        <EditorImpl
          id={`designer-floor-${floor?.id || venue.id + building.id}`}
          data={designerCompatibleData}
          legacy={false}
          saveAsGeoJson
          onClose={onCloseHandler}
          center={[lng, lat]}
          tiles={USE_GOOGLE_TILES ? null : 'mapbox://styles/mapbox/standard'}
          apps={
            <span
              className="material-icons-outlined"
              onClick={() => {
                onCloseHandler({});
              }}
              style={{
                paddingTop: '22px',
              }}>
              close
            </span>
          }>
          {USE_GOOGLE_TILES ? <GoogleTilesLayer /> : null}
        </EditorImpl>
      </MapConfigProvider>
    </div>
  );
}

function GoogleTilesLayer() {
  const [sessionToken, setSessionToken] = React.useState<string | null>(null);
  const { map } = useMapbox();

  const activeTiles = useViewStore((state: any) => state.activeTiles);

  const provider = activeTiles?.includes('satellite') ? 'satellite' : 'roadmap';

  const tileProvider = useMemo(() => new GoogleMapsTileAPI(), []);

  useEffect(() => {
    tileProvider
      .createSession(provider)
      .then((token) => {
        console.log('token', token);
        setSessionToken(token?.session);
      })
      .catch((e) => console.error(e));
  }, [provider, tileProvider]);

  useEffect(() => {
    if (map && sessionToken) {
      // @ts-ignore
      const mapIn = map;

      const link = tileProvider.getTileUrl(sessionToken);

      // if already have google-tiles source, remove it
      if (mapIn.getSource('google-tiles')) {
        mapIn.removeLayer('google-tiles-layer');
        mapIn.removeSource('google-tiles');
      }

      // Add Google Tiles as a raster source
      mapIn.addSource('google-tiles', {
        type: 'raster',
        tiles: [link],
        tileSize: 256,
        attribution: '© Google Maps',
      });

      // Add a raster layer for the Google Tiles
      mapIn.addLayer({
        id: 'google-tiles-layer',
        type: 'raster',
        source: 'google-tiles',
        minzoom: 0,
        maxzoom: 22,
      });
      map.style.stylesheet.layers.forEach(function (layer: any) {
        if (layer.type === 'symbol') {
          map.setLayoutProperty(layer.id, 'visibility', 'none');
        }
      });
    }
  }, [map, sessionToken, tileProvider]);

  return <></>;
}

export default FloorImportComponents;
