import React from 'react';
import './AssetLegend.css';
import { getTechType } from '../services/helper';

const AssetLegend: React.FC = () => {
  return (
    <div className="dropstart">
      <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: 'transparent' }}>
        <div title="Legend" className="map-icon-background mb-3">
          <span className="material-icons-outlined map-icon">info</span>
        </div>
      </a>
      <ul className="dropdown-menu p-3" style={{ width: '236px' }} aria-labelledby="dropdownMenuLink">
        <li className="legend-title mb-0">{getTechType()} Status</li>
        <li className="row-wise mt-2 vertical-center">
          <div className="preloaded-box"></div>
          <span className="legend-details">Preloaded</span>
        </li>
        <li className="row-wise mt-1 vertical-center">
          <div className="field-tech-box"></div>
          <span className="legend-details">Field Tech / RMA in Progress</span>
        </li>
        <li className="row-wise mt-1 vertical-center">
          <div className="cloud-box"></div>
          <span className="legend-details">Cloud</span>
        </li>
        <li className="row-wise mt-1 vertical-center">
          <div className="sas-box"></div>
          <span className="legend-details">SAS</span>
        </li>
        <li className="row-wise mt-1 vertical-center">
          <div className="rma-box"></div>
          <span className="legend-details">RMA Requested</span>
        </li>
        <li className="row-wise mt-1 vertical-center">
          <div className="decom-req-box"></div>
          <span className="legend-details">Decommission Requested</span>
        </li>
        <li className="row-wise mt-1 vertical-center">
          <div className="phy-decom-box"></div>
          <span className="legend-details">Physically Decommissioned</span>
        </li>
        <li className="row-wise mt-1 vertical-center">
          <div className="decom-box"></div>
          <span className="legend-details">Decommissioned</span>
        </li>
      </ul>
    </div>
  );
};

export default AssetLegend;
