import React, { useState } from "react";
import { IWifiVendorCardsProps } from "./IWifiVendorCardsProps";
import Vendor from "../Vendor";

const WifiVendorCards: React.FC<IWifiVendorCardsProps> = (props) => {
  const [activeTab, setActiveTab] = useState<"wifi" | "cpe">("wifi");
  return (
    <div>
      {/* Vendor Type selection tabs */}
      {/* <ul className="nav mt-3">
        <li
          id="wifi-vendors"
          onClick={() => {
            setActiveTab("wifi");
          }}
          className={`${activeTab === "wifi" ? "active" : ""}`}
        >
          {"Wi-Fi"}
        </li>
        <li
          id="cpe-vendors"
          onClick={() => {
            setActiveTab("cpe");
          }}
          className={`${activeTab === "cpe" ? "active" : ""}`}
        >
          {"CPE"}
        </li>
      </ul> */}

      <div className="vendor-title text-color px-3">Vendor</div>
      {/* Vendor Type selection tabs */}
      <div className="mt-3 row-wise w-100 px-3">
        <div
          id="cbsd-vendors"
          onClick={() => {
            setActiveTab("wifi");
          }}
          className={'border-left w-50 vertical-center text-color center cursor-pointer ' + `${activeTab === "wifi" ? "border-active " : "border-inactive "}`}
        >
          {"Wi-Fi"}
        </div>
        {/* {getOrganisationId() != 1 ? ( */}
        <div
          id="cpe-vendors"
          onClick={() => {
            setActiveTab("cpe");
          }}
          className={'border-right w-50 vertical-center text-color center cursor-pointer ' + `${activeTab === "cpe" ? "border-active " : "border-inactive "}`}
        >
          {"CPE"}
        </div>
        {/* ) : (
                <></>
              )} */}
      </div>

      {activeTab === "wifi" ? (
        <div className="row gx-0">
          <div className="px-3">
            <Vendor
              title="Wi-Fi"
              type="wifi_6e"
              processing={props.processing}
              selectedVendorId={props.selectedWifiVendorId}
              options={props.wifiOptions}
              refresh={props.refresh}
              onChange={props.onWifiChange}
            />
          </div>
        </div>
      ) : activeTab === "cpe" ? (
        <div className="row gx-0">
          <div className="px-3">
            <Vendor
              title="CPE"
              type="cpe_wifi"
              processing={props.processing}
              selectedVendorId={props.selectedCpeVendorId}
              options={props.cpeOptions}
              refresh={props.refresh}
              onChange={props.onCpeChange}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default WifiVendorCards;
