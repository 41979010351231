import React, { useState } from 'react';
import CBSDAssets from '../components/CBSDAssets';
import CBSDAssetSearch from '../components/CBSDAssetSearch';
import CBSDAssetFilter from '../components/CBSDAssetFilter';
import Charts from './Charts';
import './NetworkActivity.css';
import 'react-datepicker/dist/react-datepicker.css';
import { getOrganisationId } from '../services/helper';
import TabNavItem from '../components/TabNavItem';
import TabContent from '../components/TabContent';
import CBSDAssetsNav from '../components/CBSDAssetsNav';
import { CBSDStatuses } from '../constants/GlobalConstants';
import SmallPrimaryButton from '../components/SmallPrimaryButton';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import { ApiHandler } from '../services/apiHandler';
import ImportDataModal from '../components/ImportDataModal';

interface NetworkActivityProps {
  takeToMap: any; // Function to take user to map.
}

const NetworkActivity: React.FC<NetworkActivityProps> = ({ takeToMap }) => {
  const [chartFilters, setChartFilters] = useState({});
  const [searchFilters, setSearchFilters] = useState({});
  const [assetFilters, setAssetFilters] = useState({});
  const [activeTab, setActiveTab] = useState('analytics');
  const [viewType, setViewType] = useState(CBSDStatuses.FIELD_TECH.key);
  const [assetSummary, setAssetSummary] = useState({
    preloadedCount: 0,
    fieldCount: 0,
    cloudCount: 0,
    rmaCount: 0,
    sasCount: 0,
    decommissionCount: 0,
    decommissionedCount: 0,
    requestedDecommissionCount: 0,
    physicallyDecommissionedCount: 0,
  });

  const [importModal, showImportModal] = useState(false);

  const refreshAssets = (viewType: string) => {
    setViewType(viewType);
  };


  const updateChartFilters = (filters: any) => {
    setChartFilters(filters);
  };

  const updateSearchFilters = (filters: any) => {
    setSearchFilters(filters);
  };

  const updateAssetFilters = (filters: any) => {
    setSearchFilters({});
    setAssetFilters(filters);
  };
  const exportData = () => {
    // setLoading(true);
    ApiHandler({ apiName: "exportAssets", body: {}, params: {} })
      .then((response: any) => {
        // setLoading(false);
        window.open(response.data.url, "_blank");
      })
      .catch((error: any) => {
        // catchApiError(error);
      });
  };

  ;


  return (
    <div className='backdrop-color'>
      {/* Navigation Tabs */}
      <div className='row-wise space-between px-3 py-1'>
        <ul className="nav ">
          <TabNavItem className='py-2' title="Analytics" id="analytics" activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabNavItem className='py-2' title="List" id="list" activeTab={activeTab} setActiveTab={setActiveTab} />
          {/* <TabNavItem show={!isInstaller} title="Data/Export" id="data-export" activeTab={activeTab} setActiveTab={setActiveTab} /> */}
        </ul>
        <div className="row-wise vertical-center justify-content-between align-items-center">
          {/** Asset Navigations */}
         {activeTab == "list" && <div className="d-flex flex-row gap-2">

            <SecondaryButton
              clicked={() => {
                exportData();
              }}
              className='border'
              label="Export"
            // icon="upload"
            />
            <PrimaryButton
              clicked={() => {
                showImportModal(true);
              }}
              label="Import"
            />
          </div>}
        </div>
      </div>
      <div className="backdrop col-wise overflow-auto px-3 py-0">
        {/* Content of the Navigation Tabs and Navigation Icons */}
        <div>
          <TabContent id="analytics" activeTab={activeTab} >
            {/* Asset Chart view */}
            {getOrganisationId() !== 1 ? <Charts title={'Chart component'} chartFilters={updateChartFilters} /> : <></>}
          </TabContent>
          <TabContent id="list" activeTab={activeTab}>
            {/* Top bar for actions */}
            <div className="mt-3">
              <div className="row-wise justify-content-between">
                {/* Search module */}
                <CBSDAssetSearch searchFilters={updateSearchFilters} />
                <CBSDAssetsNav searchedView={viewType} summary={assetSummary} refreshAssets={refreshAssets}
                  searchFilters={searchFilters}
                />
              </div>
              {/* Filters */}
              <CBSDAssetFilter chartFilters={chartFilters} searchFilters={searchFilters} assetFilters={updateAssetFilters} />

            </div>
            {/* Asset List Table with Navigation and Pagination */}
            <CBSDAssets
              chartFilters={chartFilters}
              searchFilters={searchFilters}
              assetFilters={assetFilters}
              takeToMap={takeToMap}
              assetSummary={assetSummary}
              setAssetSummary={setAssetSummary}
              refreshAssets={refreshAssets}
              viewType={viewType}
              setViewType={setViewType}
            />
          </TabContent>
        </div>
      </div>
      <ImportDataModal show={importModal} close={() => showImportModal(false)} />

    </div>
  );
};

export default NetworkActivity;
