import React, { useState } from 'react';
// import WifiIcon from '../assets/wifi_icon.png';
import { SMB_ICONS } from '../constants/IconConstants';
import AssetProps from '../interfaces/AssetProps';
import { getOrganisationId, getRole, getTechnology } from '../services/helper';
import { AddBuilding } from './AddBuilding/AddBuilding';
import AddCpe from './AddCpe';
import { AddVenue } from './AddVenue/AddVenue';
import AddWifi from './AddWifi';
import Asset from './Asset';
import AssetDetails from './AssetDetails';
import { BuildingDetails } from './BuildingDetails/BuildingDetails';
import Calculate from './Calculate';
import EditAsset from './EditAsset';
import { EditBuilding } from './EditBuilding/EditBuilding';
import { EditVenue } from './EditVenue/EditVenue';
import { FloorActions } from './FloorActions/FloorActions';
import { FloorDetails } from './FloorDetails/FloorDetails';
import HomeActions from './homeActions';
import Measure from './Measure';
import './OutdoorRoutes.css';
import Search from './Search';
import { VenueDetails } from './VenueDetails/VenueDetails';
import { ExportOutlined, FilterOutlined, FlagOutlined, MenuOutlined, PlusCircleFilled, WifiOutlined } from '@ant-design/icons';
import ListBuildings from './ListBuildings';
import { Building } from '../custom_icons/Building';

interface OutdoorRoutesProps {
  map: any;
  currentPage: string;
  setCurrentPage: (page: string) => void;
  addAssetClicked: () => void;
  addWifiClicked: () => void;
  addCpeClicked: () => void;
  addVenueClicked: () => void;
  measureClicked: () => void;
  calculateClicked: () => void;
  setCenter: (center: any) => void;
  showSearchLocation: (location: boolean) => void;
  assetDropLocation: any;
  getAssetsInBounds: (map: any) => void;
  changeMapForAzimuth: (isActive: boolean) => void;
  goToProfile: () => void;
  rmaNewSerialNumber: string;
  setSelectedAsset: (asset: any) => void;
  assetEditAction: any;
  selectedAsset: any;
  calculateLocation: any;
  setCalculateLocation: (location: any) => void;
  getSingleAssetDetails: (trackingId: string) => void;
  setAssetEditAction: (action: any) => void;
  setRmaNewSerialNumber: (serialNumber: string) => void;
  setAssetDropLocation: (location: any) => void;
  center: any;
  setMeasurePoints: (points: any) => void;
  showMeasurePopup: (show: boolean) => void;
  setDetailsOfMeasure: (info: any) => void;
  measurePoints: any;
  assetsForMeasure: any;
  boundaryPolygon: google.maps.LatLng[] | undefined;
  setBoundaryDrawMode: (mode: 'draw' | null) => void;
  cancelBoundaryDraw: () => void;
  // setBoundaryColor: (color: string) => void;
  // boundaryColor: string;
  setCurrentVenueId: (id: string) => void;
  venueId: string;
  setBuildingsForVenue: (buildings: any) => void;
  setBuildingId: (buildingId: string) => void;
  buildingId: string;
  setFloorId: (floorId: string) => void;
  floorId: string;
  venuesOnMap: any;
  assetsInBounds: any[];
  fitVenueOnMap: () => void;
  setFloorGeoJSON: (geoJSON: any) => void;
  setFloorGeoJSONUrl: (url: string) => void;
  panToBuilding: (buildingId: string) => void;
  unsetFloorGeoJSON: () => void;
  setShowControls : any
  // setBuildingBoundaryColor : (color  :string) => void
  // buildingBoundaryColor : string;
  // buildingBoundaryPolygon : google.maps.LatLng[] | undefined;
}

const OutdoorRoutes: React.FC<OutdoorRoutesProps> = ({
  currentPage,
  setCurrentPage,
  addAssetClicked,
  addWifiClicked,
  addCpeClicked,
  addVenueClicked,
  measureClicked,
  calculateClicked,
  map,
  setCenter,
  showSearchLocation,
  assetDropLocation,
  getAssetsInBounds,
  changeMapForAzimuth,
  goToProfile,
  rmaNewSerialNumber,
  setSelectedAsset,
  assetEditAction,
  selectedAsset,
  calculateLocation,
  setCalculateLocation,
  getSingleAssetDetails,
  setAssetEditAction,
  setRmaNewSerialNumber,
  setAssetDropLocation,
  center,
  setMeasurePoints,
  showMeasurePopup,
  setDetailsOfMeasure,
  assetsForMeasure,
  measurePoints,
  boundaryPolygon,
  setBoundaryDrawMode,
  cancelBoundaryDraw,
  // setBoundaryColor,
  // boundaryColor,
  setCurrentVenueId,
  venueId,
  setBuildingsForVenue,
  setBuildingId,
  buildingId,
  setFloorId,
  floorId,
  venuesOnMap,
  assetsInBounds,
  fitVenueOnMap,
  setFloorGeoJSON,
  setFloorGeoJSONUrl,
  panToBuilding,
  unsetFloorGeoJSON,
  // setBuildingBoundaryColor ,
  // buildingBoundaryColor ,
  // buildingBoundaryPolygon
  setShowControls
}) => {
  const [floorActionType, setFloorActionType] = useState('');
  const [openList, setOpenList] = useState('')
  const [buildingNavigation, setBuildingNavigation] = useState('')
  let technologyType = getTechnology() || '';

  

  return (
    <>
      {currentPage !== 'assetDetails' && <div className='position-absolute'>
        <div className="app-card-main mx-3 mt-3" style={{ width: '350px' }}>
          <div className="row g-2 vertical-center">
            <div className='row-wise col vertical-center'>
              <div className='p-2 vertical-center center'>
                <MenuOutlined className='me-2 text-color' />
              </div>
              <div className={' p-2 center' + openList == 'venue' ? 'border-blue' : 'border'}>
                <FilterOutlined className='text-color' />
              </div>
              <div className='divider-vertical'>
              </div>
              <div onClick={() => setOpenList(list => {
                if (list == 'venue')
                  return ''
                else
                  return 'venue'
              })} className={' p-2 center' + openList == 'venue' ? 'border-blue' : 'border'}>
                <PlusCircleFilled className = {openList == 'venue' ? 'primary-blue' : 'text-color'} />
              </div>
              <div className='divider-vertical'>
              </div>
            </div>
            <div className="col">
              {technologyType == 'cbrs_5g' ? (
                <HomeActions label="CBSD" action="add" clicked={addAssetClicked} />
              ) : (
                <div className="action-box ps-1 pe-1 center vertical-center" onClick={addWifiClicked}>
                  {/* <img src={WifiIcon} className="h-20 me-2" /> */}
                 <WifiOutlined  className='me-2' style={{color:'#fff'}}/> 
                  <div className="action-label">{'Wi-Fi'}</div>
                </div>
              )}
            </div>
            {getOrganisationId() !== 1 ? (
              <div className="col">
                <div className="action-box ps-1 pe-1 center" onClick={addCpeClicked}>
                  <img src={SMB_ICONS['Field Tech White']} className="h-20 me-2" />
                  <span className="action-label">{'CPE'}</span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* {!['engineer', 'cpi', 'installer', null].includes(getRole()) && getOrganisationId() !== 1 ? (
          <div className="action-box ps-1 pe-1 center mt-2" onClick={addVenueClicked}>
            <span className="material-icons-outlined me-2" style={{ color: '#0059b2' }}>
              pin_drop
            </span>
            <span className="action-label">{'Venue'}</span>
          </div>
        ) : (
          <></>
        )}

        <hr className="" style={{ borderTop: '3px solid #ccc' }}></hr>
        <span className="home-action-heading">Utility</span>
        <div className="row gx-2 mt-2">
          <div className="col-6">
            <HomeActions label="Measure" action="measure" clicked={measureClicked} />
          </div>
          <div className="col-6">
            <HomeActions label="Calculate" action="calculate" clicked={calculateClicked} />
          </div>
        </div>*/}
        </div>
        {openList === 'venue' && <div className='app-card-main mx-3 my-1 col-wise ps-2 cursor-pointer'>
          <ListBuildings titleClassName = 'text-color' routePage = "addVenue" setCurrentPage={() => {addVenueClicked(); setOpenList('')}} title='Venue' desc='Specific place which can include more buildings' icon={<FlagOutlined className='text-color pe-2' />} />
          <ListBuildings titleClassName = 'text-color' routePage = "addBuilding" setCurrentPage={() => {setCurrentPage('addBuilding');setOpenList('');setBuildingNavigation('home')}} title='Building' desc='Specific place which can have floors' icon={<Building className='text-color pe-2' />} />
          <div className='divider-horizontal'>
          </div>
          <ListBuildings className='vertical-center' titleColor='#1677FF' height={'32px'} title='Open Design Studio' desc='' icon={<ExportOutlined className='primary-blue pe-2' />} />
        </div>}
      </div>}
      {
        currentPage === 'addAsset' ?
          <Asset
            goBack={(page: string) => {
              setCurrentPage(page);
              getAssetsInBounds(map);
            }}
            position={assetDropLocation}
            azimuthActivity={(isActive: boolean) => {
              changeMapForAzimuth(isActive);
            }}
            goToProfile={() => {
              goToProfile();
            }}
            venuesOnMap={venuesOnMap}
            setFloorGeoJSONUrl={setFloorGeoJSONUrl}
            unsetFloorGeoJSON={unsetFloorGeoJSON}
            setVenueId={setCurrentVenueId}
            setBuildingId={setBuildingId}
            setFloorId={setFloorId}
          />
        :
        currentPage === 'addCpe' ?
        <AddCpe
          goBack={(page: string) => {
            setCurrentPage(page);
            getAssetsInBounds(map);
          }}
          position={assetDropLocation}
          azimuthActivity={(isActive: boolean) => {
            changeMapForAzimuth(isActive);
          }}
          goToProfile={() => {
            goToProfile();
          }}
          venuesOnMap={venuesOnMap}
          setFloorGeoJSONUrl={setFloorGeoJSONUrl}
          unsetFloorGeoJSON={unsetFloorGeoJSON}
          setVenueId={setCurrentVenueId}
          setBuildingId={setBuildingId}
          setFloorId={setFloorId}
        />
        : currentPage === 'addWifi' ?
        <AddWifi
          goBack={(page: string) => {
            setCurrentPage(page);
            getAssetsInBounds(map);
          }}
          position={assetDropLocation}
          azimuthActivity={(isActive: boolean) => {
            changeMapForAzimuth(isActive);
          }}
          goToProfile={() => {
            goToProfile();
          }}
          venuesOnMap={venuesOnMap}
          setFloorGeoJSONUrl={setFloorGeoJSONUrl}
          unsetFloorGeoJSON={unsetFloorGeoJSON}
          setVenueId={setCurrentVenueId}
          setBuildingId={setBuildingId}
          setFloorId={setFloorId}
        />
        : currentPage === 'editAsset' ?
        <EditAsset
          rmaSerialNumber={rmaNewSerialNumber}
          goBack={(page: string) => {
            setCurrentPage(page);
            setSelectedAsset(undefined);
            getAssetsInBounds(map);
          }}
          action={assetEditAction}
          position={assetDropLocation}
          assetDetails={selectedAsset}
          azimuthActivity={(isActive: boolean) => {
            changeMapForAzimuth(isActive);
          }}
          goToProfile={() => {
            goToProfile();
          }}
          setFloorGeoJSONUrl={setFloorGeoJSONUrl}
          unsetFloorGeoJSON={unsetFloorGeoJSON}
          setBuildingId={setBuildingId}
          setFloorId={setFloorId}
          venuesOnMap={venuesOnMap}
          setVenueId={setCurrentVenueId}
        />
        : currentPage === 'calculate' ?
        <Calculate
          goBack={(page: string) => {
            setCurrentPage(page);
          }}
          latitude={calculateLocation.lat}
          longitude={calculateLocation.lng}
          setLatitude={(value: string) => {
            setCalculateLocation({ lat: value, lng: calculateLocation.lng });
            setCenter({ lat: parseFloat(value), lng: parseFloat(calculateLocation.lng) });
          }}
          setLongitude={(value: string) => {
            setCalculateLocation({ lat: calculateLocation.lat, lng: value });
            setCenter({ lat: parseFloat(calculateLocation.lat), lng: parseFloat(value) });
          }}
        />
        : currentPage === 'assetDetails' ?
        <AssetDetails
          setUpdatedAsset={(asset: AssetProps) => {
            setSelectedAsset(asset);
          }}
          refreshAsset={(trackingId: string) => {
            getSingleAssetDetails(trackingId);
            getAssetsInBounds(map);
          }}
          setAction={(action: string) => {
            setAssetEditAction(action);
          }}
          rmaSerialNumber={(value: string) => {
            setRmaNewSerialNumber(value);
          }}
          goBack={(page: string) => {
            setCurrentPage(page);
            getAssetsInBounds(map);
            if (page === 'home') {
              setSelectedAsset(undefined);
            } else if (page === 'editAsset' && selectedAsset?.asset.status !== 'SAS') {
              setAssetDropLocation(center);
            }
          }}
          asset={selectedAsset}
        />
        : currentPage === "measure" ? 
        <Measure
        goBack={(page: string) => {
          setCurrentPage(page);
          setMeasurePoints([]);
          showMeasurePopup(false);
        }}
        assetsForMeasure={assetsForMeasure}
        measurePoints={measurePoints}
        setInfoPopup={(info: any) => {
          setDetailsOfMeasure(info);
        }}
        />
      : currentPage === "addVenue" ? 
        <AddVenue
        navigateTo={(page: string) => {
          setCurrentPage(page);
          getAssetsInBounds(map);
        }}
        position={assetDropLocation}
        boundaryPolygon={boundaryPolygon}
        setBoundaryDrawMode={setBoundaryDrawMode}
        cancelBoundaryDraw={cancelBoundaryDraw}
        // setBoundaryColor={setBoundaryColor}
        // boundaryColor={boundaryColor}
        setCurrentVenueId={setCurrentVenueId}
        assetsInBounds={assetsInBounds}
        fitVenueOnMap={fitVenueOnMap}
        />
        :currentPage === "venueDetails" ?
        <VenueDetails
        navigateTo={(page: string) => {
          setCurrentPage(page);
          getAssetsInBounds(map);
        }}
        venueId={venueId}
        setBuildingsForVenue={setBuildingsForVenue}
        setBuildingId={setBuildingId}
        setBuildingNavigation = {setBuildingNavigation}
        />
        : currentPage === "editVenue" ?
        <EditVenue navigateTo={setCurrentPage} venueId={venueId} />
        : currentPage === "addBuilding" ?
        <AddBuilding
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
        position={assetDropLocation}
        venueId={venueId}
        setBoundaryDrawMode={setBoundaryDrawMode}
        cancelBoundaryDraw={cancelBoundaryDraw}
        // setBoundaryColor={setBuildingBoundaryColor}
        // boundaryColor={buildingBoundaryColor}
        boundaryPolygon={boundaryPolygon}
        assetsInBounds={assetsInBounds}
        fitVenueOnMap={fitVenueOnMap}
        navigateFrom = {buildingNavigation}
       />
        : currentPage === "buildingDetails" ? 
        <BuildingDetails
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
        buildingId={buildingId}
        setFloorId={setFloorId}
        floorId={floorId}
        setFloorActionType={setFloorActionType}
        />
        : currentPage === "editBuilding" ? 
        <EditBuilding
        buildingId={buildingId}
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
        />
        : currentPage === "floorActions" ? 
        <FloorActions
        floorId={floorId}
        buildingId={buildingId}
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
        floorActionType={floorActionType}
        position={assetDropLocation}
        setFloorGeoJSON={setFloorGeoJSON}
        setShowControls = {setShowControls}
        venueId={venueId}
        />
        : currentPage === "floorDetails" &&
        <FloorDetails
        floorId={floorId}
        buildingId={buildingId}
        navigateTo={(page: string) => {
          setCurrentPage(page);
        }}
        setFloorId={setFloorId}
        setFloorActionType={setFloorActionType}
        position={assetDropLocation}
        setFloorGeoJSONUrl={setFloorGeoJSONUrl}
        
      />
      }
    </>
  );
};

export default OutdoorRoutes;
