import React, { useEffect, useState } from "react";
import { IEditVenueProps } from "./IEditVenueProps";
import { useNavigate } from "react-router-dom";
import LabelledInput from "../LabelledInput";
import PrimaryButton from "../PrimaryButton";
import Spinner from "../Spinner";
import ConfirmAlert from "../confirmAlert";
import ErrorAlert from "../ErrorAlert";
import SuccessAlert from "../SuccessAlert";
import WarningAlert from "../WarningAlert";
import { ApiHandler } from "../../services/apiHandler";
import { CloseOutlined } from "@ant-design/icons";
import LabelledInputNew from "../LabelledInputNew";

export const EditVenue: React.FC<IEditVenueProps> = (props) => {
  let navigate = useNavigate();
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [venue, setVenue] = useState({
    name: "",
    description: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  });

  useEffect(() => {
    getVenue();
  }, []);

  const handleInputChange = (e: any) => {
    setVenue((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const getVenue = () => {
    setLoading(true);
    ApiHandler({ apiName: "getVenue", body: {}, params: { venue_id: props.venueId } })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setVenue(response.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const updateVenue = () => {
    setLoading(true);
    ApiHandler({ apiName: "updateVenue", body: venue, params: { venue_id: props.venueId } })
      .then((response) => {
        setLoading(false);
        setSuccess({ message: "Venue Updated Successfully", alert: true });
        setTimeout(() => {
          props.navigateTo("venueDetails");
        }, 1500);
      })
      .catch((error) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  return (
    <div className="h-100 overflow-auto">
      <div className="app-card position-relative app-card-size right">
        <div className="row-wise vertical-center space-between px-4 py-3">
          {/* <div
            className="icon-background-active"
            onClick={() => {
              props.navigateTo("venueDetails");
            }}
          >
            <span className="material-icons-round icon-active">arrow_back</span>
          </div> */}

          <span className="app-card-action-heading">EDITING VENUE</span>
          <div
              // className="icon-background-active"
              onClick={() => {
                props.navigateTo("venueDetails");
              }}>
              <CloseOutlined className='fs-6' />
              {/* <span className="material-icons-round icon-active">arrow_back</span> */}
            </div>
        </div>
        <div className='border-bottom' />

        <form className="overflow-auto col-wise h-100 px-4 py-3">
          <LabelledInputNew
            title="Name*"
            name="name"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={venue.name}
            placeholder="Enter"
          />
          <div className="form-floating w-100 mb-2">

          <div className='text-color'>
          Description
              </div>

            <textarea
              rows={5}
              id="description"
              className=" textbox-box input-text h-auto w-100 p-2"
              placeholder={"Description"}
              name="description"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={venue.description}
            ></textarea>
            {/* <label className="input-label" htmlFor="description">
              Description
            </label> */}
          </div>

          <div className="form-floating w-100 mb-2">

          <div className='text-color'>
          Address
              </div>
            <textarea
              rows={5}
              id="address"
              className="form-control textbox-box input-text h-auto w-100 p-2"
              placeholder={"Address"}
              name="address"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={venue.address}
            ></textarea>
            {/* <label className="input-label" htmlFor="address">
              Address*
            </label> */}
          </div>

          <div className="row gx-2">
            <div className="col-6">
              <LabelledInputNew
                title="Zip Code*"
                name="zipcode"
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                value={venue.zipcode}
                placeholder="Enter"
              />
            </div>
            <div className="col-6">
              <LabelledInputNew
                title="City*"
                name="city"
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                value={venue.city}
                placeholder="Enter"
              />
            </div>
          </div>

          <div className="row gx-2">
            <div className="col-6">
              <LabelledInputNew
                title="State*"
                name="state"
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                value={venue.state}
                placeholder="Enter"
              />
            </div>
            <div className="col-6">
              <LabelledInputNew
                title="Country*"
                name="country"
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                value={venue.country}
                placeholder="Enter"
              />
            </div>
          </div>
        </form>

<div className="px-4 py-3">
        <PrimaryButton
          className=""
          label="Save Changes"
          clicked={() => {
            setConfirmAlert(true);
          }}
        /></div>
      </div>

      <Spinner show={loading} />

      <ConfirmAlert
        message={`Do you confirm the changes in the venue?`}
        primaryText={"Yes"}
        secondaryText={"No"}
        secondaryAction={() => {
          setConfirmAlert(false);
        }}
        visible={confirmAlert}
        title={` Confirm Changes `}
        primaryAction={() => {
          setConfirmAlert(false);
          updateVenue();
        }}
        onClose={() => {
          setConfirmAlert(false);
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};
