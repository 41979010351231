import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Divider, Drawer, Radio, Row, Select, Space } from 'antd';
import SasProviders from '../constants/SasProviders';
import { CloseOutlined } from '@ant-design/icons';
import { getTechnology } from '../services/helper';
import '../App.css'
import LabelLessInput from './LabelLessInput';
import { ApiHandler } from '../services/apiHandler';
import { useNavigate } from 'react-router-dom';
import ErrorAlert from './ErrorAlert';
import SuccessAlert from './SuccessAlert';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';

interface Props {
    open: boolean;
    setOpenDrawer: (val: boolean) => void
}

export default function TechnologyDrawer(props: Props) {

    const navigate = useNavigate()

    const [techType, setTechType] = useState(getTechnology())
    const [selectedProviderType, setSelectedProviderType] = useState<'sas' | 'afc' | null>('sas');

    const [selectedSasProvider, setSasProvider] = useState('');
    const [apiKey, setApiKey] = useState('')
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [allOptions, setAllOptions] = useState<any>()
    const [selectedDeploymentType, setSelectedDeploymentType] = useState('')
    const [deployOption5g, setDeployOptions5g] = useState<any>()
    const [vendorOptionList, setVendorOptionList] = useState<any[]>([])
    const [selectedVendor, setSelectedVendor] = useState('')
    const [modelOptionList, setModelOptionList] = useState<any[]>([])
    const [selectedModal, setSelectedModal] = useState('')

    useEffect(() => {
        listVendors()
    }, [])

    const listVendors = () => {
        ApiHandler({ apiName: 'listVendors', params: {}, body: {} })
            .then((response: any) => {
                let vendor: any = response?.data;
                console.group(response, "reonse")
                setAllOptions(vendor)
                handleOptions(vendor)
                // setAerialVendorList(vendor.aerial);
                // setRoeVendorList(vendor.roe);
                // setSmbVendorList(vendor.smb);
                // setWifiVendorList(vendor.wifi);
                // setCpeWifiVendorList(vendor.cpe_wifi);
                // setCpeCbsdVendorList(vendor.cpe_cbsd);
                // getUser();
            })
            .catch((response: any) => {
                if (response.doLogout === true) {
                    setErrorMessage('Session Timed Out');
                    setErrorAlert(true);
                    setTimeout(() => navigate('/login', { replace: true }), 2000);
                } else {
                    setErrorMessage(response.data.message);
                    setErrorAlert(true);
                }
            });
    };


    const getUser = () => {
        //   setIsNotCPI(['installer', 'engineer'].includes(getRole()));
        ApiHandler({ apiName: 'getUser', params: {}, body: {} })
            .then((response: any) => {
                let user: any = response.data;
                // setUserId(user.user_id || '');
                setSasProvider(user.default_provider || '');
                //   setAerialVendorId(user.default_aerial_vendor_id || 0);
                //   setRoeVendorId(user.default_roe_vendor_id || 0);
                //   setSmbVendorId(user.default_smb_vendor_id || 0);
                //   setWifiVendorId(user.default_wifi_vendor_id || 0);
                //   setCpeWifiVendorId(user.default_cpe_wifi_vendor_id || 0);
                //   setCpeCbsdVendorId(user.default_cpe_cbsd_vendor_id || 0);
                //   setCpiName(user.cpi_name || '');
                //   setUsername(user.username || '');
                //   setEmail(user.email || '');
                //   setCpiId(user.cpi_id || '');
                //   setCertificateUploaded(user.is_p12_uploaded || false);
                //   setCertificateFile(undefined);
                //   setCertPassword('');
                //   setProfilePicture(user);
                //   setTechnologyTypeTitle(user.technology_type);
                //   setSelectedProviderType(user.provider_type);
                //   setLoading(false);
                //   allowSettingsSave(false);
            })
            .catch((response: any) => {
                //   setLoading(false);
                if (response.doLogout === true) {
                    setErrorMessage('Session Timed Out');
                    setErrorAlert(true);
                    setTimeout(() => navigate('/login', { replace: true }), 2000);
                } else {
                    setErrorMessage(response.data.message);
                    setErrorAlert(true);
                }
            });
    };




    const handleOptions = (vendorList: any) => {
        if (Object.keys(vendorList).length > 0) {
            let deployOption5gTemp = { 'cbrs_5g': [] as any, 'wifi_6e': [] as any }
            Object.keys(vendorList).map(key => {
                var label = key
                if (key.length > 3 && key.split('_').length === 1) {
                    label = key.charAt(0).toUpperCase() + key.slice(1)
                }
                else
                    label = key.split('_')[0].toUpperCase()
                var value = key
                if (key.includes('wifi')) {
                    if (label == 'Wifi') label = 'Wi-Fi'
                    deployOption5gTemp['wifi_6e'].push({ label: label, value: value })
                }
                else {
                    deployOption5gTemp['cbrs_5g'].push({ label: label, value: value })
                }
            })
            setDeployOptions5g(deployOption5gTemp)
        }
    }

    const techOptions = [
        { value: 'cbrs_5g', label: '5G' },
        { value: 'wifi_6e', label: 'Wi-Fi' }
    ]
    const changeDeploymentType = (val: string) => {
        setSelectedDeploymentType(val)
        setSelectedVendor('')
        setSelectedModal('')
        console.log(val, "all opt", allOptions)
        if (allOptions[val]) {
            var vendorTemp: any[] = []
            // var optionTemp: any[] = []
            if (allOptions[val].length > 0) {
                allOptions[val].map((opt: any) => {
                    vendorTemp.push({ label: opt['vendor_name'], value: opt['vendor_name'] })
                    // optionTemp.push({ label: opt['vendor_model'], value: opt['vendor_model'] })

                })
            }
            setVendorOptionList(vendorTemp)
            // setModelOptionList(optionTemp)
        }
    }

    const changeTechType = (e: any) => {
        setTechType(e.target.value)
        if (e.target.value == "wifi_6e")
            setSelectedProviderType('afc')
        else if (e.target.value == "cbrs_5g")
            setSelectedProviderType('sas')
        setSelectedDeploymentType('')
        setSelectedVendor('')
        setSelectedModal('')
    }
    const changeVendorType = (e: string) => {
        setSelectedVendor(e)
        if (allOptions[selectedDeploymentType]) {
            const fIndex = allOptions[selectedDeploymentType].findIndex((item: any) => item['vendor_name'] == e)
            if (fIndex !== -1){
                setModelOptionList([{label : allOptions[selectedDeploymentType][fIndex]['vendor_model'], option : allOptions[selectedDeploymentType][fIndex]['vendor_model']}])
                setSelectedModal(allOptions[selectedDeploymentType][fIndex]['vendor_model'])
            }
        }
    }
    const saveTech = () => {
        if(techType){
            localStorage.setItem('technology_type', techType)
            window.location.reload()
        }
    }

    return (

        <Drawer title={
            <div className='text-color px-2 py-1 justify-content-between vertical-center'>
                <span>
                    Active Technology
                </span>
                <CloseOutlined onClick={() => props.setOpenDrawer(false)} className='cursor-pointer text-color-secondary' />
            </div>
        }
            closeIcon={null}
            open={props.open} width='50%' className='bg-sec'>
            <Col span={24} className='col-wise space-between px-2 h-100'>
                <Row>
                    <Col span={6} className='col-wise'>
                        <div className='sub-text '>
                            Choose technology
                        </div>
                        <div>
                            <Radio.Group className='col-wise mt-2 gap-8' options={techOptions} value={techType} onChange={(e) => changeTechType(e)} />
                        </div>
                    </Col>
                    <Divider type='vertical' className='h-100 px-2' />
                    <Col span={16}>
                        <div className='sub-text '>
                            Settings
                        </div>
                        <div className='my-2'>
                            {techType == "cbrs_5g" && <Checkbox onChange={(e) => {
                                if (e.target.value == 'sas' && selectedProviderType == 'sas')
                                    setSelectedProviderType(null)
                                else
                                    setSelectedProviderType('sas')

                            }} value="sas" checked={selectedProviderType === 'sas' ? true : false}> CBRS (SAS)</Checkbox>}
                            {techType == "wifi_6e" && <Checkbox onChange={(e) => {
                                if (e.target.value == 'afc' && selectedProviderType == 'afc')
                                    setSelectedProviderType(null)
                                else
                                    setSelectedProviderType('afc')

                            }} value="afc" checked={selectedProviderType === 'afc' ? true : false}> AFC </Checkbox>}
                        </div>
                        {techType === 'cbrs_5g' && selectedProviderType === 'sas' &&
                            <>
                                <div className='sub-text py-2'>
                                    SAS Provider
                                </div>
                                <Radio.Group value={selectedSasProvider}>
                                    <div className='row-wise gap-8 vertical-center'>
                                        {SasProviders.map((provider: any) => {
                                            return (
                                                <div key={provider.alt} className="row-wise gap-8"
                                                    onClick={() => {
                                                        setSasProvider(provider.alt);
                                                    }}>
                                                    <Radio checked={selectedSasProvider === provider.alt} value={provider.alt}>
                                                        <div
                                                            key={provider}
                                                            // className={selectedSasProvider === provider.alt ? 'sas-provider-active' : 'sas-provider'}
                                                            className='sas-provider'
                                                        >
                                                            <img src={provider.img} alt={provider.alt}></img>
                                                        </div>
                                                    </Radio>
                                                </div>
                                            );
                                        })
                                        }
                                    </div>
                                </Radio.Group>
                            </>
                        }
                        <div className='sub-text py-2'>
                            API key  <span className='text-color-secondary'>(optional)</span>
                        </div>

                        <LabelLessInput
                            //   title={'API key (optional)'}
                            name="api_key"
                            changeVal={(e: any) => {
                                setApiKey(e.target.value)
                            }}
                            value={apiKey}
                            placeholder='Add'
                            w100
                        />
                        <div className='sub-text py-2'>
                            Deployment Type
                        </div>
                        <Select placeholder='Select' value={selectedDeploymentType ? selectedDeploymentType : undefined} className='w-100' options={deployOption5g && deployOption5g[techType]} onChange={changeDeploymentType} />
                        {vendorOptionList.length > 0 &&
                            <div className='space-between vertical-center gap-8'>
                                <div className='col-wise w-100'>
                                    <div className='sub-text py-2'>
                                        Vendor Make
                                    </div>

                                    <Select placeholder='Select' className='w-100' value={selectedVendor ? selectedVendor : undefined} onChange={(e) => changeVendorType(e)} options={vendorOptionList} />

                                </div>
                                <div className='col-wise w-100'>

                                    <div className='sub-text py-2'>
                                        Model
                                    </div>
                                    <Select placeholder='Select'
                                        className='w-100' value={selectedModal ? selectedModal : undefined}
                                        onChange={setSelectedModal} 
                                        options={modelOptionList}
                                    />
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
                <Row justify={'end'}>
                    <div className='row-wise vertical-center px-4 pt-3 pb-2 gap-3 right'>
                        <SecondaryButton
                            className="w-100"
                            label={"Cancel"}
                            clicked={() => props.setOpenDrawer(false)}
                            disable={false}
                        />
                        <PrimaryButton className="w-100 blue-bg" label="Save" clicked={() => saveTech()} disable={false} />
                    </div>
                </Row>
            </Col>
            <ErrorAlert
                show={errorAlert}
                onDismiss={() => {
                    setErrorAlert(false);
                }}
                message={errorMessage}
            />
            <SuccessAlert
                show={successAlert}
                onDismiss={() => {
                    setSuccessAlert(false);
                }}
                message={successMessage}
            />
        </Drawer>
    )
} 