import { Circle, GoogleMap, InfoWindow, Marker, Polygon, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AssetDropIcon from '../assets/AssetDropIconNew.svg';
import BuildingIcon from '../assets/building_location.png';
import MyLocationIcon from '../assets/locateme.png';
import VenueIcon from '../assets/venue_location.png';
import AssetLegend from '../components/AssetLegend';
import CustomPopup from '../components/CustomInfoMaps/CustomMap';
import ErrorAlert from '../components/ErrorAlert';
import LocateMe from '../components/LocateMe';
import MapSpinner from '../components/MapSpinner';
import OutdoorRoutes from '../components/OutdoorRoutes';
import ToggleMapType from '../components/ToggleMapType';
import ZoomMinus from '../components/ZoomMinus';
import ZoomPlus from '../components/ZoomPlus';
import { assetStatusDot, wifiStatusIcon } from '../constants/GlobalConstants';
import MAP_API_KEY, { INSTALLER_RADIUS } from '../constants/map';
import AssetProps, { AssetObj } from '../interfaces/AssetProps';
import { ApiHandler } from '../services/apiHandler';
import { getClusterIcon, getMarkerIcon, getOrganisationId, getRole, getTechnology } from '../services/helper';
import './Outdoor.css';

interface OutdoorProps {
  preSelectedAsset: AssetObj | undefined; // This is used to preselect an asset on the map. This is used when user clicks on "Show on Map" in Network Activity.
  goToProfile: () => void;
  searchedLocationMap: boolean;
  currentPageMap: string;
  centerMap: any;
}

const Outdoor: React.FC<OutdoorProps> = ({ preSelectedAsset = undefined, goToProfile, searchedLocationMap, currentPageMap, centerMap }) => {
  let navigate = useNavigate();
  const [libraries] = useState<Libraries>(['places', 'geometry', 'drawing']);

  const [assetDropLocation, setAssetDropLocation] = useState({ lat: 0, lng: 0 });
  const [calculateLocation, setCalculateLocation] = useState({ lat: '', lng: '' });
  const [assets, setAssets] = useState<any[]>([]);
  const [venues, setVenues] = useState<any[]>([]);
  const [fetchAssets, setFetchAssets] = useState(false);
  const [currentPage, setCurrentPage] = useState('home');
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const [searchedLocation, setSearchedLocation] = useState(false);
  const [isAzimuthActive, setIsAzimuthActive] = useState(false);
  const [map, setMap] = useState<google.maps.Map>();
  const [mapTilesLoaded, setMapTilesLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [assetHover, setAssetHover] = useState(false);
  const [measurePoints, setMeasurePoints] = useState<any[]>([]);
  const [assetsForMeasure, setAssetsForMeasure] = useState<string[]>([]);
  const [measurePopup, setMeasurePopup] = useState<boolean>(false);
  const [measureInfo, setMeasureInfo] = useState<any>({});
  const [hoverData, setHoverData] = useState({
    position: { lat: 0, lng: 0 },
    trackingId: '',
    serialNumber: '',
    mountingType: '',
    technologyType: '',
    status: '',
    cbrsNodeName: '',
    vendor_type: '',
  });
  const [selectedAsset, setSelectedAsset] = useState<AssetProps | undefined>(undefined);
  const [assetEditAction, setAssetEditAction] = useState('');
  const [rmaNewSerialNumber, setRmaNewSerialNumber] = useState('');
  const [venueBoundaryPolygon, setVenueBoundaryPolygon] = useState<google.maps.Polygon>();
  const [drawMode, setDrawMode] = useState<'draw' | 'draw_building' | null>(null);
  // const [venueBoundaryColor, setVenueBoundaryColor] = useState('#0059b2');
  const [currentVenueId, setCurrentVenueId] = useState('');
  const [currentBuildings, setCurrentBuildings] = useState<any[]>([]);
  const [currentBuildingId, setCurrentBuildingId] = useState('');
  const [currentFloorId, setCurrentFloorId] = useState('');
  const [floorPlanGeometry, setFloorPlanGeometry] = useState<any>(null);
  // const [buildingBoundaryPolygon, setBuildingBoundaryPolygon] = useState<google.maps.Polygon>();
  // const [buildingBoundaryColor, setBuildingBoundaryColor] = useState('#0059b2');
  const [showControls, setShowControls] = useState(true)
  const [center, setCenter] = useState({
    lat: 39.600292,
    lng: -104.889944,
  });

  const getTechnologyName: any = {
    wifi_6e: 'Wi-Fi',
    cbrs_5g: 'CBSD',
    cpe_wifi: 'CPE WiFi',
    cpe_cbsd: 'CPE CBSD',
  };

  const mapStyle = {
    width: '100%',
    height: 'calc(100vh - 58px)',
    zIndex: drawMode !== null ? 9 : undefined,
  };

  const mapOptions = {
    minZoom: 4,
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ],
    keyboardShortcuts: false,
    tilt: 0,
  };

  const { isLoaded } = useJsApiLoader({
    id: 'map',
    googleMapsApiKey: MAP_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (selectedAsset !== undefined) {
      setCurrentPage('assetDetails');
    }
    fetchAssetsOnIdle();
  }, [selectedAsset]);

  useEffect(() => {
    if (centerMap) {
      setCenter(centerMap);
      map?.setZoom(20);
    }
  }, [centerMap]);

  useEffect(() => {
    if (typeof searchedLocationMap !== undefined) {
      setSearchedLocation(searchedLocationMap);
    }
  }, [searchedLocationMap]);

  useEffect(() => {
    if (currentPageMap) {
      setCurrentPage(currentPageMap);
    }
  }, [currentPageMap]);

  useEffect(() => {
    if (currentPage === 'editAsset') {
      setAssetDropLocation({
        lat: selectedAsset?.asset.latitude ?? 0,
        lng: selectedAsset?.asset.longitude ?? 0,
      });
    }
    if (currentPage === 'home') {
      setCurrentVenueId('');
      setCurrentBuildingId('');
      setCurrentFloorId('');
    }
    if (!['floorDetails', 'floorActions', 'assetDetails', 'editAsset'].includes(currentPage)) {
      unsetGeoJSON();
    }
    if (currentPage === 'buildingDetails') {
      panToBuilding(currentBuildingId);
    }
    if (currentPage === 'venueDetails') {
      panToVenue(currentVenueId);
    }
  }, [currentPage]);

  useEffect(() => {
    if (mapTilesLoaded) {
      fetchAssetsOnIdle(); // Fetch assets when map tiles are loaded
      if (preSelectedAsset) {
        assetInFocus(preSelectedAsset);
        preSelectedAsset = undefined;
      }
    }
  }, [mapTilesLoaded]);

  useEffect(() => {
    if (currentFloorId && currentFloorId !== '') getAssetsInFloor(currentFloorId);
    else if (currentBuildingId && currentBuildingId !== '') getAssetsInBuilding(currentBuildingId);
    else if (currentVenueId && currentVenueId !== '') getAssetsInVenue(currentVenueId);
    else getAssetsInBounds(map);
  }, [currentVenueId, currentBuildingId, currentFloorId]);

  useEffect(() => {
    fetchAssetsOnIdle();
  }, [fetchAssets]);

  const panToBuilding = (buildingId: any) => {
    if (map && currentBuildings.length > 0) {
      let building_location = currentBuildings.filter((building) => building.id === buildingId);
      if (building_location.length > 0) {
        map.setZoom(20);
        map.panTo(building_location[0].location);
      }
    }
  };

  const panToVenue = (venueId: any) => {
    if (map && venues.length > 0) {
      let venue_location = venues.filter((venue) => venue.id.toString() === venueId.toString());
      if (venue_location.length > 0) {
        map.setZoom(18);
        map.panTo(venue_location[0].location);
      }
    }
  };

  const setUserLocationCenter = () => {
    try {
      if (!navigator.geolocation) {
        centerUserLocation(null, false);
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          centerUserLocation(pos, true);
        },
        (e: any) => {
          console.log('Error fetching user location: ', e);
        },
        { timeout: 3000 }
      );
    } catch (e: any) {
      setErrorMessage(e);
      setErrorAlert(true);
    }
  };

  const setErrorMsg = (error: any) => {
    if (error.doLogout === true) {
      setErrorMessage('Session Timed Out');
      setErrorAlert(true);
      setTimeout(() => navigate('/login', { replace: true }), 2000);
      return;
    }
    setErrorMessage(error.data.message);
    setErrorAlert(true);
  };

  const fetchAssetsOnIdle = () => {
    if (fetchAssets) {
      if (currentFloorId && currentFloorId !== '') getAssetsInFloor(currentFloorId);
      else if (currentBuildingId && currentBuildingId !== '') getAssetsInBuilding(currentBuildingId);
      else if (currentVenueId && currentVenueId !== '') getAssetsInVenue(currentVenueId);
      else getAssetsInBounds(map);
    }
  };

  const getAssetsInBounds = async (map: google.maps.Map | undefined) => {
    const zoomLevel = map?.getZoom();
    const bounds = map?.getBounds();
    if (zoomLevel === undefined || bounds === undefined) return;
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const requestBody: any = {
      northEast: {
        lat: ne.lat(),
        lng: ne.lng(),
      },
      southWest: {
        lat: sw.lat(),
        lng: sw.lng(),
      },
      zoom: zoomLevel,
      technology_type: getTechnology(),
    };
    if (getRole() === 'installer' && getOrganisationId() === 1) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          requestBody.location = pos;
          assetsInBoundsApi(requestBody, zoomLevel);
        },
        (e: any) => {
          console.log('Error fetching user location: ', e);
        },
        { timeout: 3000 }
      );
    } else {
      assetsInBoundsApi(requestBody, zoomLevel);
    }
  };

  // Below API depends on getAssetsInBounds function
  const assetsInBoundsApi = (requestBody: any, zoomLevel: any) => {
    setLoading(true);
    setFetchAssets(false);
    ApiHandler({ apiName: 'assetsInBounds', body: requestBody, params: {} })
      .then(async (response: any) => {
        if (zoomLevel > 15) {
          setAssetSectorIcons(response);
        } else if (zoomLevel <= 13) {
          let clusters: any[] = [];
          for (let asset of response.data) {
            let icon: google.maps.Icon = await getClusterIcon();
            clusters.push({
              ...asset,
              clusterIcon: icon,
              clusterLabel: asset.totalassets.toString(),
            });
          }
          setAssets(clusters);
          setTimeout(() => {
            setAssets(clusters);
          }, 500);
        } else {
          setAssets(response.data.assets ?? []);
          setVenues(response.data.venues ?? []);
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        setErrorMsg(error);
      });
  };

  const getAssetsInVenue = (venueId: any) => {
    setFetchAssets(false);
    if (!venueId || venueId === '') return;
    setLoading(true);
    ApiHandler({
      apiName: 'assetsInVenue',
      body: {},
      params: { venue_id: venueId, technology_type: getTechnology() },
    })
      .then((response: any) => {
        setAssetSectorIcons(response);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        setErrorMsg(error);
      });
  };

  const getAssetsInBuilding = (buildingId: any) => {
    setFetchAssets(false);
    if (!buildingId || buildingId === '') return;
    setLoading(true);
    ApiHandler({
      apiName: 'assetsInBuilding',
      body: {},
      params: { building_id: buildingId, technology_type: getTechnology() },
    })
      .then((response: any) => {
        setAssetSectorIcons(response);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        setErrorMsg(error);
      });
  };

  const getAssetsInFloor = (floorId: any) => {
    setFetchAssets(false);
    if (!floorId || floorId === '') return;
    setLoading(true);
    ApiHandler({
      apiName: 'assetsInFloor',
      body: {},
      params: { floor_id: floorId, technology_type: getTechnology() },
    })
      .then(async (response: any) => {
        setAssetSectorIcons(response);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        setErrorMsg(error);
      });
  };

  const setAssetSectorIcons = async (response: any) => {
    let assetsWithIcon: any[] = [];
    for (let asset of response.data.assets) {
      let icon: google.maps.Icon = await getMarkerIcon(asset);
      assetsWithIcon.push({
        ...asset,
        markerIcon: icon,
        markerLabel: asset.tracking_id,
      });
    }
    setAssets(assetsWithIcon);
    if (response?.data?.venues) setVenues(response.data.venues);
    setTimeout(() => {
      setAssets(assetsWithIcon);
    }, 500);
  };

  const centerUserLocation = (position: any, isLocated: boolean) => {
    if (isLocated) {
      setUserLocation(position);
      setCenter(position);
      map?.setZoom(18);
    }
  };

  const onMapLoad = useCallback(function callback(map: any) {
    setMap(map);
    if (preSelectedAsset) return;
    if (localStorage.getItem('center')) {
      console.log(localStorage.getItem('center'), 'consol3');
      setCenter(JSON.parse(localStorage.getItem('center') ?? '{lat: 39.600292, lng: -104.889944,}'));
    } else setUserLocationCenter();
  }, []);

  const onMapUnmount = useCallback(function callback(map: any) {
    setMap(undefined);
  }, []);

  const clickedOnMap = (e: any) => {
    if (currentPage === 'editAsset' && selectedAsset?.asset.status === 'SAS') return;
    if (drawMode === 'draw') {
      venueBoundaryPolygon?.setPath([...(venueBoundaryPolygon.getPath()?.getArray() ?? []), e.latLng]);
      return;
    }
    // if (drawMode === "draw_building") {
    //   buildingBoundaryPolygon?.setPath([...(buildingBoundaryPolygon.getPath()?.getArray() ?? []), e.latLng])
    //   return
    // }
    setAssetDropLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    setCalculateLocation({ lat: e.latLng.lat().toFixed(6), lng: e.latLng.lng().toFixed(6) });
    setSearchedLocation(false);
    localStorage.setItem('center', JSON.stringify({ lat: e.latLng.lat(), lng: e.latLng.lng() }));
  };

  const addAssetClicked = () => {
    setCurrentPage('addAsset');
    setAssetDropLocation(center);
  };

  const addCpeClicked = () => {
    setCurrentPage('addCpe');
    setAssetDropLocation(center);
  };

  const addWifiClicked = () => {
    setCurrentPage('addWifi');
    setAssetDropLocation(center);
  };

  const addVenueClicked = () => {
    setCurrentPage('addVenue');
    setAssetDropLocation(center);
  };

  const measureClicked = () => {
    setCurrentPage('measure');
  };

  const calculateClicked = () => {
    setCurrentPage('calculate');
    setCalculateLocation({ lat: center.lat.toFixed(6), lng: center.lng.toFixed(6) });
  };

  const changeMapForAzimuth = (isActive: boolean) => {
    setIsAzimuthActive(isActive);
    if (isActive) {
      map?.setZoom(22);
      setCenter(assetDropLocation);
      map?.setOptions({ gestureHandling: 'none' });
    } else {
      map?.setOptions({ gestureHandling: 'auto' });
    }
  };

  const assetClicked = (asset: any) => {
    if (currentPage === 'measure') {
      if (measurePoints.length < 2 && ![null, undefined].includes(asset.tracking_id)) {
        setMeasurePoints([...measurePoints, { lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) }]);
        setAssetsForMeasure([...assetsForMeasure, asset.tracking_id]);
        return;
      }
      return;
    }
    let zoomLevel: number | undefined = map?.getZoom();
    if (zoomLevel && zoomLevel <= 13) {
      map?.setZoom(zoomLevel + 1);
      map?.setCenter({ lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) });
      return;
    }
    assetInFocus(asset);
  };

  const assetMouseHover = (asset: any) => {
    let zoomLevel: number | undefined = map?.getZoom();
    if (zoomLevel && zoomLevel > 13) {
      setAssetHover(true);
      setHoverData({
        position: { lat: asset.latitude, lng: asset.longitude },
        serialNumber: asset.serial_number,
        trackingId: asset.tracking_id,
        mountingType: asset.mounting_type,
        technologyType: asset.technology_type,
        status: asset?.status,
        cbrsNodeName: asset?.cbrs_node_name,
        vendor_type: asset?.vendor_type,
      });
    }
  };

  const assetInFocus = (asset: any) => {
    map?.setZoom(22);
    setCenter({ lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) });
    getSingleAssetDetails(asset.tracking_id);
    localStorage.setItem('center', JSON.stringify({ lat: parseFloat(asset.latitude), lng: parseFloat(asset.longitude) }));
  };

  const getSingleAssetDetails = (trackingId: string) => {
    setLoading(true);
    ApiHandler({ apiName: 'getAsset', body: {}, params: { tracking_id: trackingId } })
      .then((response: any) => {
        setLoading(false);
        const assetData: AssetProps = response.data;
        setSelectedAsset(assetData);
      })
      .catch((error: any) => {
        setLoading(false);
        setErrorMsg(error);
      });
  };

  const setMarkerIcon = (markerIcon: any, clusterIcon: any, status: any, technologyType: any) => {
    let wifiIcon: any = {
      url: wifiStatusIcon[status],
      scaledSize: new google.maps.Size(16, 16),
      anchor: new google.maps.Point(8, 8),
      labelOrigin: new google.maps.Point(8, 20),
    };

    if (markerIcon) {
      if (technologyType === 'wifi_6e') return wifiIcon;
      return markerIcon;
    } else if (clusterIcon) {
      return clusterIcon;
    }
    return {
      url: assetStatusDot[status] ? assetStatusDot[status] : '',
      scaledSize: new google.maps.Size(10, 10),
      anchor: new google.maps.Point(5, 5),
      labelOrigin: new google.maps.Point(5, 10),
    };
  };

  const setMarkerLabel = (markerLabel: any, clusterLabel: any) => {
    if (markerLabel) {
      return {
        text: markerLabel,
        fontFamily: 'Inter',
        fontSize: '10px',
        color: '#000',
      };
    } else if (clusterLabel) {
      return {
        text: clusterLabel,
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#fff',
      };
    }
    return '';
  };

  const setDetailsOfMeasure = (info: any) => {
    setMeasureInfo(info);
    setMeasurePopup(true);
  };

  const eraseVenuePolygon = (e: any) => {
    let newpolygon = [...(venueBoundaryPolygon?.getPath()?.getArray() ?? [])].filter((value) => {
      return value.lat() !== e.latLng.lat() || value.lng() !== e.latLng.lng();
    });
    venueBoundaryPolygon?.setPath(newpolygon);
  };

  const cancelBoundaryDraw = () => {
    setDrawMode(null);
    venueBoundaryPolygon?.setPath([]);
  };

  // const setBoundaryColor = (color: string) => {
  //   setVenueBoundaryColor(color);
  // };

  const setBoundaryDrawMode = (mode: 'draw' | null) => {
    setDrawMode(mode);
  };

  const setBuildingsForVenue = (buildings: any) => {
    setCurrentBuildings(buildings);
  };

  const fitVenueOnMap = () => {
    if (map && venueBoundaryPolygon) {
      let bounds = new google.maps.LatLngBounds();
      venueBoundaryPolygon.getPath()?.forEach((latLng) => {
        bounds.extend(latLng);
      });
      map.fitBounds(bounds);
      fetchAssetsOnIdle();
    }
  };

  const setGeoJSON = (geojSON: any) => {
    map?.data.addGeoJson(geojSON);
    map?.data.setStyle({
      strokeWeight: 1,
      fillOpacity: 0.7,
      fillColor: '#0059b2', //"#ff9444",
      strokeColor: '#0059b2',
    });
  };

  const setGeoJSONUrl = (url: any) => {
    // map?.data.loadGeoJson(url);
    // map?.data.setStyle({
    //   strokeWeight: 1,
    //   fillOpacity: 1,
    //   fillColor: '#cccccc',
    //   strokeColor: '#aaaaaa',
    // });
    setFloorPlanGeometry(url);
  };

  const unsetGeoJSON = () => {
    setFloorPlanGeometry(null);
    // map?.data.forEach((feature) => {
    //   map.data.remove(feature);
    // });
  };

  return isLoaded ? (
    <GoogleMap
      onClick={clickedOnMap}
      mapContainerStyle={mapStyle}
      center={center}
      zoom={15}
      onTilesLoaded={() => {
        if (!mapTilesLoaded) {
          setMapTilesLoaded(true);
        }
      }}
      onLoad={onMapLoad}
      onUnmount={onMapUnmount}
      options={mapOptions}
      clickableIcons={false}
      onDragEnd={() => {
        setCenter({
          lat: map?.getCenter()?.lat() || 0,
          lng: map?.getCenter()?.lng() ?? 0,
        });
        setFetchAssets(true);
        localStorage.setItem(
          'center',
          JSON.stringify({
            lat: map?.getCenter()?.lat(),
            lng: map?.getCenter()?.lng(),
          })
        );
      }}
      onZoomChanged={() => {
        setFetchAssets(true);
      }}>
      <OutdoorRoutes
        addAssetClicked={addAssetClicked}
        addWifiClicked={addWifiClicked}
        addVenueClicked={addVenueClicked}
        addCpeClicked={addCpeClicked}
        assetDropLocation={assetDropLocation}
        assetEditAction={assetEditAction}
        assetsForMeasure={assetsForMeasure}
        calculateClicked={calculateClicked}
        calculateLocation={calculateLocation}
        center={center}
        changeMapForAzimuth={changeMapForAzimuth}
        currentPage={currentPage}
        getAssetsInBounds={(map: any) => {
          setAssets([]);
          setFetchAssets(true);
        }}
        getSingleAssetDetails={getSingleAssetDetails}
        goToProfile={goToProfile}
        map={map}
        measureClicked={measureClicked}
        measurePoints={measurePoints}
        rmaNewSerialNumber={rmaNewSerialNumber}
        selectedAsset={selectedAsset}
        setAssetDropLocation={setAssetDropLocation}
        setAssetEditAction={setAssetEditAction}
        setCalculateLocation={setCalculateLocation}
        setCenter={setCenter}
        setCurrentPage={setCurrentPage}
        setDetailsOfMeasure={setDetailsOfMeasure}
        setMeasurePoints={setMeasurePoints}
        setRmaNewSerialNumber={setRmaNewSerialNumber}
        setSelectedAsset={setSelectedAsset}
        showMeasurePopup={setMeasurePopup}
        showSearchLocation={setSearchedLocation}
        boundaryPolygon={venueBoundaryPolygon?.getPath()?.getArray()}
        cancelBoundaryDraw={cancelBoundaryDraw}
        // setBoundaryColor={setBoundaryColor}
        setBoundaryDrawMode={setBoundaryDrawMode}
        // boundaryColor={venueBoundaryColor}
        setCurrentVenueId={(venueId: string) => {
          setCurrentVenueId(venueId);
        }}
        venueId={currentVenueId}
        setBuildingsForVenue={setBuildingsForVenue}
        setBuildingId={(buildingId: string) => {
          setCurrentBuildingId(buildingId);
        }}
        buildingId={currentBuildingId}
        setFloorId={(floorId: string) => {
          setCurrentFloorId(floorId);
        }}
        floorId={currentFloorId}
        venuesOnMap={venues}
        assetsInBounds={assets}
        fitVenueOnMap={fitVenueOnMap}
        setFloorGeoJSON={(geoJSON: any) => {
          setGeoJSON(geoJSON);
        }}
        setFloorGeoJSONUrl={(url: any) => {
          setGeoJSONUrl(url);
        }}
        panToBuilding={panToBuilding}
        unsetFloorGeoJSON={unsetGeoJSON}
        setShowControls={setShowControls}
      />

      {showControls && (
        <>
          <div
            className={
              "position-fixed col-wise " +
              (currentPage == "home"
                ? "position-right-actions"
                : "shifted-position-right-actions")
            }
          >
            <LocateMe
              located={(coordinates: any, found: boolean) => {
                centerUserLocation(coordinates, found);
              }}
              error={(error: any) => {
                setErrorMessage(error.message);
                setErrorAlert(error.alert);
              }}
            />
            <ToggleMapType
              change={() => {
                map?.getMapTypeId() === google.maps.MapTypeId.ROADMAP
                  ? map?.setMapTypeId(google.maps.MapTypeId.HYBRID)
                  : map?.setMapTypeId(google.maps.MapTypeId.ROADMAP);
              }}
            />
            <AssetLegend />
          </div>

          <div
            className={
              "position-fixed col-wise " +
              (currentPage == "home"
                ? "position-zoom-actions"
                : "shifted-position-zoom-actions")
            }
          >
            <ZoomPlus
              map={map}
              clicked={(currentZoom: number) => {
                map?.setZoom(currentZoom + 1);
              }}
            />
            <ZoomMinus
              map={map}
              clicked={(currentZoom: number) => {
                map?.setZoom(currentZoom - 1);
              }}
            />
          </div>
        </>
      )}

      {floorPlanGeometry &&
        floorPlanGeometry.features.length > 0 &&
        floorPlanGeometry.features[0].geometries.map((geom: any) => {
          return <Polygon paths={geom.coordinates} options={{ strokeColor: '#0059b2', fillColor: '#0059b2', fillOpacity: 0.15, strokeWeight: 1 }} />;
        })}

      {assets &&
        assets.map((asset: any) => {
          return (
            <Marker
              key={asset.tracking_id}
              icon={setMarkerIcon(asset?.markerIcon, asset?.clusterIcon, asset?.status, asset?.technology_type)}
              label={setMarkerLabel(asset?.markerLabel, asset?.clusterLabel)}
              onMouseOver={() => {
                assetMouseHover(asset);
              }}
              onMouseOut={() => {
                setAssetHover(false);
              }}
              position={{
                lat: parseFloat(asset.latitude),
                lng: parseFloat(asset.longitude),
              }}
              onClick={() => {
                assetClicked(asset);
              }}
              draggable={false}
              options={{ map: map }}
            />
          );
        })}

      {measurePoints &&
        measurePoints.map((point: any, index: number) => {
          return (
            <Marker
              icon={AssetDropIcon}
              key={point.lat + point.lng}
              position={{
                lat: parseFloat(point.lat),
                lng: parseFloat(point.lng),
              }}
              clickable={false}
              draggable={false}
            />
          );
        })}

      {measurePoints && measurePoints.length > 1 && (
        <Polyline
          options={{
            icons: [
              {
                icon: {
                  path: 'M 0,-1 0,1',
                  scale: 4,
                  strokeColor: '#FF9445',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: '#FF9445',
                  fillOpacity: 0.35,
                },
                offset: '0',
                repeat: '20px',
              },
            ],
            strokeColor: '#FF9445',
            strokeOpacity: 0,
            strokeWeight: 2,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            zIndex: 1,
          }}
          path={measurePoints}
        />
      )}

      {/* Polygon used for creating venue */}

      {getOrganisationId() !== 1 ? (
        <Polygon
          onLoad={(polygon) => {
            setVenueBoundaryPolygon(polygon);
          }}
          onClick={eraseVenuePolygon}
          paths={venueBoundaryPolygon?.getPath()}
          options={{
            strokeColor: '#0059b2',
            fillColor: '#0059b2',
            fillOpacity: 0.15,
            editable: drawMode !== null,
            strokeWeight: 1,
          }}
        />
      ) : (
        <></>
      )}

      {/* Polygons of existing venues */}
      {venues &&
        !floorPlanGeometry &&
        getOrganisationId() !== 1 &&
        venues.map((venue: any) => {
          if (venue.polygon === undefined) return;
          // when zoomed in, show all venue polygons
          return (
            <Polygon
              paths={venue.polygon}
              options={{
                strokeWeight: 1,
                fillOpacity: 0.5,
                fillColor: '#e5eef7', //"#ff9444",
                strokeColor: '#0059b2',
              }}
              onClick={(e: any) => {
                console.log(e.latLng.lat(), e.latLng.lng());
                setAssetDropLocation({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                });
                if (currentPage !== 'home') return;
                map?.setCenter(venue.location);
                setCurrentVenueId(venue.id);
                setCurrentPage('venueDetails');
              }}
              key={venue.id}
            />
          );
        })}

      {/* Locations of existing venues */}
      {venues &&
        !floorPlanGeometry &&
        getOrganisationId() !== 1 &&
        venues.map((venue: any) => {
          if (venue.location === undefined) return;
          // when zoomed in, show all venue polygons
          return (
            <Marker
              position={venue.location}
              visible={[null, ''].includes(currentVenueId) && [null, ''].includes(currentBuildingId) && [null, ''].includes(currentFloorId)}
              icon={{
                url: VenueIcon,
                scale: 1,
                anchor: new google.maps.Point(12, 12),
                scaledSize: new google.maps.Size(24, 24),
              }}
              onClick={() => {
                if (currentPage !== 'home') return;
                map?.setCenter(venue.location);
                setCurrentVenueId(venue.id);
                setCurrentPage('venueDetails');
                setAssetDropLocation(venue.location);
              }}
              key={venue.id}
            />
          );
        })}

      {/* Locations of existing buildings */}
      {currentBuildings &&
        !floorPlanGeometry &&
        getOrganisationId() !== 1 &&
        currentBuildings.map((building: any) => {
          return (
            <Marker
              key={building?.id}
              position={building.location}
              visible={(![null, ''].includes(currentVenueId) || ![null, ''].includes(currentBuildingId)) && [null, ''].includes(currentFloorId)}
              icon={{
                url: BuildingIcon,
                scale: 1,
                anchor: new google.maps.Point(10, 10),
                scaledSize: new google.maps.Size(20, 20),
              }}
              onClick={() => {
                setCurrentBuildingId(building.id);
                setCurrentPage('buildingDetails');
              }}
            />
          );
        })}

      <Marker
        position={userLocation}
        icon={{
          url: MyLocationIcon,
          scaledSize: new google.maps.Size(18, 18),
          anchor: new google.maps.Point(9, 9),
        }}
        clickable={false}
        draggable={false}
        visible={userLocation.lat !== 0 && userLocation.lng !== 0}
      />

      <Circle
        center={userLocation}
        visible={userLocation.lat !== 0 && userLocation.lng !== 0 && getRole() === 'installer' && getOrganisationId() === 1}
        radius={INSTALLER_RADIUS}
        options={{
          strokeColor: '#0059B2',
          fillColor: '#0059B2',
          fillOpacity: 0.1,
          strokeOpacity: 0.5,
          strokeWeight: 1,
          clickable: false,
          draggable: false,
          editable: false,
        }}
      />

      <Marker
        position={assetDropLocation}
        icon={{ url: AssetDropIcon }}
        draggable={true}
        onDragEnd={(e: any) => {
          setAssetDropLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        }}
        options={{ draggable: true, optimized: true }}
        visible={
          (currentPage === 'editAsset' || ['addAsset', 'addVenue', 'addBuilding', 'addWifi', 'addCpe'].includes(currentPage)) && !isAzimuthActive
        }
      />

      <Marker
        position={{
          lat: parseFloat(calculateLocation.lat),
          lng: parseFloat(calculateLocation.lng),
        }}
        icon={{ url: AssetDropIcon }}
        draggable={true}
        onDragEnd={(e: any) => {
          setCalculateLocation({
            lat: e.latLng.lat().toFixed(6),
            lng: e.latLng.lng().toFixed(6),
          });
        }}
        options={{ draggable: true, optimized: true }}
        visible={currentPage === 'calculate'}
      />

      <Marker position={center} clickable={false} draggable={false} visible={searchedLocation} />
      {assetHover && (
        // <InfoWindow
        //   options={{
        //     pixelOffset: map?.getZoom() && map?.getZoom()! >= 15 ? new google.maps.Size(0, -35) : new google.maps.Size(0, -10),
        //   }}
        //   position={hoverData.position}

        //   >
        <CustomPopup map={map as google.maps.Map} position={hoverData.position} visible={assetHover}>
          <div className="col-wise info-win">
            <span className="infowindow-title">{getOrganisationId() !== 1 || hoverData.vendor_type !== 'Nokia' ? 'Site ID 1' : 'UID'} </span>
            <span className="infowindow-label">{hoverData.trackingId ?? '-'}</span>

            <span className="infowindow-title">Serial Number </span>
            <span className="infowindow-label">{hoverData.serialNumber === '' ? '-' : hoverData.serialNumber ?? '-'}</span>

            <span className="infowindow-title">CBRS Node Name </span>
            <span className="infowindow-label">{hoverData.cbrsNodeName ?? '-'}</span>

            {!['wifi_6e', 'cpe_cbsd', 'cpe_wifi'].includes(hoverData.technologyType) && <span className="infowindow-title">Mounting Type </span>}
            {!['wifi_6e', 'cpe_cbsd', 'cpe_wifi'].includes(hoverData.technologyType) && (
              <span className="infowindow-label">{hoverData.mountingType}</span>
            )}
            {getOrganisationId() !== 1 ? <span className="infowindow-title">Device Type </span> : <></>}

            {getOrganisationId() !== 1 ? <span className="infowindow-label">{getTechnologyName[hoverData.technologyType]}</span> : <></>}

            <span className="infowindow-title">Status </span>
            <span className="infowindow-label">{hoverData.status}</span>
            <div className='left-arrow' />
          </div>
        </CustomPopup>
        // </InfoWindow>
      )}

      {measurePopup &&
        measurePoints.map((point: any, index: number) => {
          return (
            <InfoWindow
              key={point.lat.toFixed(0) + point.lng.toFixed(0)}
              options={{
                pixelOffset: map?.getZoom() && map?.getZoom()! >= 15 ? new google.maps.Size(0, -35) : new google.maps.Size(0, -10),
              }}
              position={point}>
              <div className="col-wise">
                <div className="row-wise">
                  <span className="infowindow-label">Tilt: </span>
                  <span className="infowindow-title ms-2">{`${measureInfo.tilt} deg`}</span>
                </div>

                <div className="row-wise">
                  <span className="infowindow-label">Height: </span>
                  <span className="infowindow-title ms-2">{`${measureInfo.height[index]} ft`}</span>
                </div>
              </div>
            </InfoWindow>
          );
        })
      }
      <MapSpinner show={loading} />
      <ErrorAlert
        show={errorAlert}
        onDismiss={() => {
          setErrorAlert(false);
        }}
        message={errorMessage}
      />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Outdoor;
